import {FC, useCallback, useEffect, useState} from 'react'
import {Box, Button, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Alert, Link} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import CreatableSelect from 'react-select/creatable'
import ChipsLabelTeam from '../chipsLabelTeam/ChipsLabelTeam'
import {usePromptCreationContext} from '../../context/PromptCreationContext'
import {usePromptsContext} from '../../context/PromptsContext'
import {AIModels} from '../../types/AiModel'
import './SaveSummarisedPrompt.scss'
import {SingleValue} from 'react-select'
import {SelectOption} from '../../types/SelectOption'
import {useAbortController} from '../../hooks/useAbortController'
import {useFeedbackContext} from '../../context/FeedbackContext'
import {Prompt} from '../../types/Prompt'
import {getHomeTabForAnalytics, TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useUserContext} from '../../context/UserContext'
import {usePromptEdition} from '../../hooks/usePromptEdition'
import {MarkdownEditor} from '../aiPromptTextarea/MarkdownEditor'
import {PromptChatDetail} from '../../types/PromptChatDetail'
import {useAiModelsContext} from '../../context/AIModelsContext'
import HelpIcon from '@mui/icons-material/Help'
import {SAVE_PROMPT_DOCUMENTATION_URL} from '../../constants/Links'

type Props = {
    promptChatDetails: PromptChatDetail
    tempPromptId: string
    onUpdate: () => void
    onClose: () => void
    open: boolean
}

const SaveSummarisedPrompt: FC<Props> = ({
    promptChatDetails,
    tempPromptId,
    onUpdate,
    onClose,
    open
}) => {

    const {modelId: currentModelId, messages} = promptChatDetails
    const {aiModels} = useAiModelsContext()

    const {
        promptId,
        promptTeams,
        aiPromptCompilationLoading,
        title, setTitle,
        promptLabels, setPromptLabels,
        promptChats, setPromptChats,
        isPublic, setIsPublic,
        createPrompt,
        getPromptCompilation,
        getPromptTemplate,
        setUnsavedChanges,
        setValueHandler,
    } = usePromptCreationContext()
    const {labels,setLabels} = usePromptsContext()
    const {abortControllerRefSignal} = useAbortController()
    const {showFeedback} = useFeedbackContext()
    const {user} = useUser()
    const {selectedTab, token} = useUserContext()
    const {editPrompt} = usePromptEdition(token)

    const [promptCompilationExecuting, setPromptCompilationExecuting] = useState<boolean>(false)

    const compilationSummary = promptChats.find(chat => chat.modelId === currentModelId)?.compilationSummary
    const hasPromptCompilation = compilationSummary && Object.keys(compilationSummary).length && compilationSummary.summary?.length

    const generatePromptCompilationHandler = useCallback(async () => {
        if (aiPromptCompilationLoading) return
        const userMessages = messages.filter(message => message.sender === 'user').map(message => message.text)
        setPromptChats(prevValue =>
            prevValue.some(chat => chat.modelId === currentModelId) ?
                prevValue.map(chat => chat.modelId === currentModelId ? {...chat, compilationSummary: {summary: ''}} : chat)
                : prevValue)

        return getPromptCompilation({
            userPrompt: '',
            format: '',
            temperature: '',
            frequencyPenalty: '',
            language: '',
            audience: '',
            aiPrompt: '',
            description: '',
            title: '',
            tone: '',
            labels: [],
            teams: [],
            modelId: currentModelId,
            isPublic: false,
        }, abortControllerRefSignal, userMessages, promptId || tempPromptId).then(aiCompilation => {
            if (!aiCompilation && userMessages.length > 1) {
                showFeedback('Error', 'The AI model selected could not return a response. Please try again or use a different model.', 'error', 10)
            }
        }).catch(() => {
            showFeedback('Error', 'The AI model selected could not return a response. Please try again or use a different model.', 'error', 10)
        })
    }, [showFeedback, abortControllerRefSignal, messages, promptId, currentModelId, getPromptCompilation, tempPromptId, aiPromptCompilationLoading, setPromptChats])

    const savePromptHandler = async () => {
        createPrompt({...getPromptTemplate(), modelId: currentModelId, aiPrompt: compilationSummary?.summary || '', tempPromptId})
            .then((createdPrompt?: Prompt) => {
                if (createdPrompt) {
                    showFeedback('Prompt saved!', 'The prompt was saved!', 'success')
                    setUnsavedChanges(false)
                } else showFeedback('Error', 'The prompt couldn\'t be saved, please try again.', 'error')
            })
            .catch(() => {
                showFeedback('Error', 'The prompt couldn\'t be saved, please try again.', 'error')
            })
            .finally(() => {
                TrackActionEvent('Prompt', user?.externalId ?? user?.id, {
                    action: 'save',
                    origin: getHomeTabForAnalytics(selectedTab),
                    prompt_id: promptId,
                    is_public: isPublic
                })
            })
    }

    const updatePromptHandler = async () => {
        if (!promptId) {
            showFeedback('Error', 'No prompt found!', 'error')
            return
        }
        const updatedPrompt = {id: promptId, ...getPromptTemplate(), aiPrompt: compilationSummary?.summary || ''}
        await editPrompt(updatedPrompt)
            .then(() => {
                showFeedback('Success', 'The prompt was updated!', 'success')
                onUpdate()
            })
            .catch(() => {
                showFeedback('Error', 'There was an error updating the prompt! Please, try again', 'error', 10)
            })
            .finally(() => {
                TrackActionEvent('Prompt', user?.externalId ?? user?.id, {
                    action: 'update',
                    origin: getHomeTabForAnalytics(selectedTab),
                    prompt_id: promptId,
                    is_public: isPublic
                })
            })
    }

    const saveChatCompilationHandler = async () => {
        const analyticsSaveChatCompilation = {action: 'save'}
        if (promptId) analyticsSaveChatCompilation['prompt_id'] = promptId
        TrackActionEvent('Save chat compilation', user?.externalId ?? user?.id, analyticsSaveChatCompilation)
        !promptId ? await savePromptHandler() : await updatePromptHandler()
        onClose()
    }

    useEffect(() => {
        if (!promptCompilationExecuting && (!hasPromptCompilation || compilationSummary?.isChatUpdated)) {
            setPromptCompilationExecuting(true)
            generatePromptCompilationHandler()
        }
    }, [generatePromptCompilationHandler, promptCompilationExecuting, promptChats, compilationSummary, hasPromptCompilation])

    const labelsHandler = (selectedLabel: SingleValue<SelectOption>) => {
        setValueHandler(setPromptLabels, promptLabels.indexOf(selectedLabel!.value) === -1 ? [selectedLabel!.value, ...promptLabels] : promptLabels, 'labels')
        setValueHandler(setLabels, labels.indexOf(selectedLabel!) === -1 ? [selectedLabel!, ...labels] : labels, 'labelOptions')
    }

    const changePromptSummaryHandler = (value: string) => {
        setPromptChats((prevValue: PromptChatDetail[]) => {
            const chatIndex = prevValue.findIndex(chat => chat.modelId === currentModelId)
            if (chatIndex === -1) return prevValue

            const updatedChat: PromptChatDetail = {...prevValue[chatIndex], compilationSummary: { summary: value }}
            return [...prevValue.slice(0, chatIndex), updatedChat, ...prevValue.slice(chatIndex + 1)]
        })
    }

    return (
        <Dialog className='savePromptContainer'
                onClose={onClose}
                open={open}>
            <DialogTitle className='savePromptTitle'>
                <span>Save summarised prompt</span>
                <CloseIcon className='closeIcon' cursor='pointer' onClick={onClose}/>
            </DialogTitle>
            <DialogContent className='savePromptDialogContent'>
                <DialogContentText className='savePromptDescriptionText'>Your chat has been simplified into a single prompt. You can edit this prompt before you save it.</DialogContentText>
                <Box className='savePromptCompilationContainer'>
                    <DialogContentText className='fieldLabel'>Prompt compilation</DialogContentText>
                    <Box className='promptCompilationField'>
                        <MarkdownEditor aiText={compilationSummary?.summary || ''}
                                        label='prompt'
                                        loadingText=''
                                        isLoading={false}
                                        placeholderValue=''
                                        onChangeHandler={changePromptSummaryHandler}
                        />
                    </Box>
                    <Alert className='newPromptAlert' severity='info'>The new prompt may give slightly different results when used</Alert>
                </Box>
                <Box className='savePromptName'>
                    <DialogContentText className='fieldLabel'>Name*</DialogContentText>
                    <TextField
                        required
                        inputProps={{maxLength: 150}}
                        className='textFieldInput textFieldPromptName'
                        onChange={event => setValueHandler(setTitle, event.target.value, 'title')}
                        placeholder='Name your summarised prompt'
                        fullWidth
                        variant='outlined'
                        value={title}
                    />
                </Box>
                <DialogContentText className='fieldLabel'>Label</DialogContentText>
                <CreatableSelect isSearchable
                                 options={labels}
                                 value={null}
                                 placeholder='Eg. Marketing'
                                 className='creatableSelect labelCreatableSelect'
                                 classNamePrefix='creatableSelect'
                                 components={{ IndicatorSeparator: () => null }}
                                 menuPortalTarget={document.body}
                                 styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                 onChange={labelsHandler}/>
                <ChipsLabelTeam chips={promptLabels || []} isLabel/>
                <ChipsLabelTeam chips={promptTeams || []}/>
                <Box className='privacyContainer'>
                    <DialogContentText className='sectionTitle'>Make prompt visible to others</DialogContentText>
                    <Box className='privacyToggleContainer'>
                        <DialogContentText className='privacyDescriptionText'>
                            The saved prompt is shared within your company and will be visible to colleagues
                        </DialogContentText>
                        <Switch className='privacyToggle' checked={isPublic} onChange={event => setValueHandler(setIsPublic, event.target.checked, 'isPublic')}/>
                    </Box>
                </Box>
                <Box className='promptInputFieldContainer'>
                    <DialogContentText className='sectionTitle'>AI Model</DialogContentText>
                    <DialogContentText className='promptInputValue'>{aiModels?.find(({id}) => id === currentModelId)?.name ?? AIModels[currentModelId]?.name}</DialogContentText>
                </Box>
            </DialogContent>
            <DialogActions className='savePromptActionsContainer'>
                <Button className='savePromptButton' variant='contained' disabled={title.length === 0 || compilationSummary?.summary?.length === 0} onClick={saveChatCompilationHandler}>
                    Save prompt
                </Button>
            </DialogActions>
            <Box className='helpContainer'>
                <HelpIcon className='helpIcon'/>
                <DialogContentText className='helpText'>Need help? Check user's
                    <Link className='helpText' href={SAVE_PROMPT_DOCUMENTATION_URL} target='_blank'> documentation</Link>
                </DialogContentText>
            </Box>
        </Dialog>
    )
}

export default SaveSummarisedPrompt