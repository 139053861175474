import {
    Alert,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    InputAdornment, Paper,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography
} from '@mui/material'
import {DatePicker} from 'rsuite'
import EventIcon from '@mui/icons-material/Event'
import React, {ChangeEvent, useState} from 'react'
import {endOfDay} from 'date-fns'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {Budget, BudgetPeriodicityCustomType, BudgetPeriodicityType, BudgetType} from '../../../../types/Budget'
import {useUser} from '@clerk/clerk-react'
import {useBudgetsContext} from '../../../../context/BudgetContext'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import './BudgetForm.scss'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import {capitalizeFirstLetter} from '../../../../utils/textUtils'
import Select from 'react-select'
import {BUDGET_PERIODICITY_CUSTOM_OPTIONS} from '../../../../constants/BudgetConstants'
import {getPeriodicityInDays} from '../../../../utils/budgetUtils'
import PaymentsIcon from '@mui/icons-material/PaymentsOutlined'
import CloseIcon from '@mui/icons-material/Close'

type BudgetFormProps = {
    type: BudgetType
}

export const BudgetForm = ({type}: BudgetFormProps) => {
    const {toggleDrawer} = useToggleDrawerContext()
    const {user} = useUser()
    const {overallBudget, saveBudget, selectedGroupId} = useBudgetsContext()
    const groupId = selectedGroupId

    const [budgetNeverEnds, setBudgetNeverEnds] = useState<boolean>(true)
    const [startDate, setStartDate] = useState<Date>()
    const [endDate, setEndDate] = useState<Date>()
    const [amount, setAmount] = useState<string>()
    const [periodicity, setPeriodicity] = useState<BudgetPeriodicityType>('Monthly')
    const [amountValidationError, setAmountValidationError] = useState<string>()
    const [startDateValidationError, setStartDateValidationError] = useState<boolean>(false)
    const [endDateValidationError, setEndDateValidationError] = useState<boolean>(false)
    const [timeUnit, setTimeUnit] = useState<BudgetPeriodicityCustomType | undefined>('Months')
    const [periodicityNumber, setPeriodicityNumber] = useState<number>()
    const [periodicityNumberValidationError, setPeriodicityNumberValidationError] = useState<boolean>(false)
    const [timeUnitValidationError, setTimeUnitValidationError] = useState<boolean>(false)

    const eventType = type === 'Organization' ? 'CREATE_OVERALL_BUDGET' : 'CREATE_TEAM_BUDGET'

    const onOpenCalendarHandler = () => {
        TrackActionEvent('Budget calendar', user?.externalId ?? user?.id, {action: 'open', type})
    }

    const onCleanHandler = () => {
        TrackActionEvent('Budget calendar', user?.externalId ?? user?.id, {action: 'clean', type})
    }

    const onStartDateHandler = (date: Date | null) => {
        setStartDateValidationError(false)
        TrackActionEvent('Budget', user?.externalId ?? user?.id, {action: 'set_start_date', type})
        setStartDate(date ?? undefined)
        setEndDate(undefined)
    }

    const onEndDateHandler = (date: Date | null) => {
        setEndDateValidationError(false)
        TrackActionEvent('Budget', user?.externalId ?? user?.id, {action: 'set_end_date', type})
        if (date) setEndDate(date)
    }

    const onEndDateOptionHandler = (_, value: string) => {
        const isBudgetNeverEnds = value === 'never'
        if (isBudgetNeverEnds) setEndDate(undefined)
        setBudgetNeverEnds(isBudgetNeverEnds)
    }

    const onSetOrganizationLimitHandler = async (event: React.MouseEvent) => {
        const invalidEndDate = (!endDate && !budgetNeverEnds)
        const parsedAmount = parseFloat(amount ?? '')
        const isAmountInvalid = isNaN(parsedAmount) || parsedAmount <= 0
        const isPeriodicityNumberInvalid = periodicity === 'Custom' && !periodicityNumber
        const isTimeUnitInvalid = periodicity === 'Custom' && !timeUnit
        if (isAmountInvalid) setAmountValidationError(undefined)
        if (isPeriodicityNumberInvalid) setPeriodicityNumberValidationError(true)
        if (isTimeUnitInvalid) setTimeUnitValidationError(true)
        if (!startDate) setStartDateValidationError(true)
        if (startDate && invalidEndDate) setEndDateValidationError(true)
        if (!startDate || invalidEndDate || isAmountInvalid || isPeriodicityNumberInvalid || isTimeUnitInvalid) return

        const offset = startDate.getTimezoneOffset() * -1
        startDate.setTime(startDate.getTime() + offset * 60000)
        if (endDate) endDate.setTime(endDate.getTime() + offset * 60000)

        const budget: Partial<Budget> = {
            periodicityInDays: getPeriodicityInDays({
                type: periodicity,
                customType: timeUnit ?? 'Months',
                unit: periodicityNumber ?? 1
            }),
            amount: parsedAmount,
            startDate: startDate?.getTime(),
            periodStartDate: startDate?.getTime(),
            endDate: endDate?.getTime() ?? new Date('9999-12-31').getTime(),
            groupId,
            periodicity,
            ...(periodicity === 'Custom' ?
                {
                    periodicityCustomType: timeUnit,
                    periodicityCustomUnit: periodicityNumber
                } : undefined)
        }
        const createdBudget = await saveBudget(budget)
        if (createdBudget) {
            TrackActionEvent('Budget', user?.externalId ?? user?.id, {
                action: 'save',
                periodicity,
                amount,
                type,
                has_budget_timeframe: !budgetNeverEnds
            })
            toggleDrawer(false, eventType)(event)
        }
    }

    const handlePeriodicityType = (value: string) => {
        setPeriodicity(capitalizeFirstLetter(value) as BudgetPeriodicityType)
    }

    const handlePeriodicityNumber = (event: ChangeEvent<HTMLInputElement>) => {
        setPeriodicityNumberValidationError(false)
        setPeriodicityNumber(Number(event.target.value))
    }

    const onAmountHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const amount = event.target.value
        const parsedAmount = parseFloat(amount)
        if (groupId && overallBudget?.amount && parsedAmount > overallBudget.amount) {
            setAmountValidationError('Team limit can\'t be higher than the organization limit')
        } else {
            if (isNaN(parsedAmount) || parsedAmount <= 0) setAmountValidationError('Amount must be a number greater than 0')
            else setAmountValidationError(undefined)
        }
        setAmount(amount)
    }

    return <Stack className='BudgetForm_Container'>
        <Box className='BudgetForm_TitleContainer'>
            <Box className='BudgetForm_Title'>
                <Box className='BudgetForm_TitleIcon'>
                    <PaymentsIcon fontSize='small'/>
                </Box>
                <Typography variant='h4'>Set {type === 'Group' ? 'team' : 'organisation'} limit</Typography>
            </Box>
            <CloseIcon onClick={toggleDrawer(false, eventType)} cursor='pointer'
                       className='BudgetForm_CloseIcon'/>
        </Box>
        <Box className='BudgetForm_SpendingLimit'>
            <Typography variant='h6' className='BudgetForm_Subtitle'>
                Spending limit
            </Typography>
            <Box>
                <Typography variant='body2' className='BudgetForm_AmountLabel'>Amount</Typography>
                <TextField
                    fullWidth
                    placeholder='Eg. 20,000'
                    value={amount}
                    onChange={onAmountHandler}
                    error={!!amountValidationError}
                    helperText={amountValidationError}
                    InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                    className='BudgetForm_TextField'
                />
                <Alert
                    severity='info'
                    icon={<InfoIcon fontSize='small'/>}
                    className='BudgetForm_InfoAlert'
                >
                    <Typography>Costs are estimated. Actual bill may vary.</Typography>
                </Alert>
            </Box>
        </Box>

        <Box className='BudgetForm_CycleContainer'>
            <Typography variant='h6' className='BudgetForm_Subtitle'>
                Spending limit cycle
            </Typography>
            <Typography variant='body2' className='BudgetForm_Description'>
                Choose how often your spending limit resets
            </Typography>
            <RadioGroup
                row
                defaultValue={'monthly'}
                onChange={(_, value) => handlePeriodicityType(value)}
                className='BudgetForm_RadioGroup'
            >
                <Paper variant='outlined' className='BudgetForm_Radio cycle'>
                    <FormControlLabel
                        value='monthly'
                        control={<Radio
                            checkedIcon={<RadioButtonCheckedIcon className='BudgetForm_RadioCheckedIcon'/>}/>}
                        label='Monthly'
                    />
                </Paper>
                <Paper variant='outlined' className='BudgetForm_Radio cycle'>
                    <FormControlLabel
                        value='quarterly'
                        control={<Radio
                            checkedIcon={<RadioButtonCheckedIcon className='BudgetForm_RadioCheckedIcon'/>}/>}
                        label='Quarterly'
                    />
                </Paper>
                <Paper variant='outlined' className='BudgetForm_Radio cycle'>
                    <FormControlLabel
                        value='custom'
                        control={<Radio
                            checkedIcon={<RadioButtonCheckedIcon className='BudgetForm_RadioCheckedIcon'/>}/>}
                        label='Custom'
                    />
                </Paper>
            </RadioGroup>

            {periodicity === 'Custom' && (
                <Stack direction='row' spacing={2}>
                    <Box className='BudgetForm_TimeUnit'>
                        <Typography variant='body2' gutterBottom className='BudgetForm_TimeUnitLabel'>
                            Time unit
                        </Typography>
                        <FormControl fullWidth component='div' className='BudgetForm_FormControl'>
                            <Select required className={`BudgetForm_Creatable ${timeUnitValidationError ? 'hasError' : ''}`} placeholder='Select a time unit'
                                    options={BUDGET_PERIODICITY_CUSTOM_OPTIONS.map(unit => ({
                                        value: unit,
                                        label: unit
                                    }))}
                                    value={timeUnit ? {label: timeUnit, value: timeUnit} : null}
                                    onChange={(event) => setTimeUnit(event.values()?.next()?.value?.value)}
                                    styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                                    menuPortalTarget={document.body} isMulti
                                    isOptionDisabled={() => !!timeUnit}
                                    components={{IndicatorSeparator: () => <></>}}
                                    isClearable={false}
                            />
                        </FormControl>
                    </Box>
                    <Box className='BudgetForm_PeriodNumber'>
                        <Typography variant='body2' gutterBottom className='BudgetForm_PeriodNumberLabel'>
                            Number
                        </Typography>
                        <TextField
                            fullWidth
                            variant='outlined'
                            value={Number(periodicityNumber).toString()}
                            onChange={handlePeriodicityNumber}
                            size='small'
                            error={periodicityNumberValidationError}
                            type='number'
                            inputProps={{min: 1}}
                            className='BudgetForm_PeriodNumberTextField'
                        />
                    </Box>
                </Stack>
            )}
        </Box>

        <Box className={`BudgetForm_DatesContainer ${periodicity === 'Custom' ? 'customPadding' : ''}`}>
            <Typography variant='h6' className='BudgetForm_Subtitle'>
                Effective Dates
            </Typography>
            <Typography variant='body2' className='BudgetForm_Description'>
                Choose the date range over which the spending limit cycles will be applied.
            </Typography>
            <Typography variant='body2' className='BudgetForm_DateLabel'>Date</Typography>
            <Box className='BudgetForm_DateRadioFields'>
                <Box className='BudgetForm_DateFields'>
                    <DatePicker
                        oneTap
                        className={`BudgetForm_DatePicker ${startDateValidationError ? 'hasError' : ''}`}
                        placeholder='YYYY/MM/DD'
                        caretAs={EventIcon}
                        format='yyyy/MM/dd'
                        shouldDisableDate={(date: Date) => date < new Date()}
                        onChange={onStartDateHandler}
                        onOpen={onOpenCalendarHandler}
                        onClean={onCleanHandler}
                        placement='topEnd'
                    />
                    <DatePicker
                        oneTap
                        className={`BudgetForm_DatePicker ${endDateValidationError ? 'hasError' : ''}`}
                        placeholder='YYYY/MM/DD'
                        caretAs={EventIcon}
                        disabled={budgetNeverEnds}
                        format='yyyy/MM/dd'
                        shouldDisableDate={(date: Date) => date <= (endOfDay(startDate || new Date()))}
                        onChange={onEndDateHandler}
                        onOpen={onOpenCalendarHandler}
                        onClean={onCleanHandler}
                        placement='topEnd'
                    />
                </Box>
                <FormControl component='div'>
                    <RadioGroup
                        defaultValue={'never'}
                        onChange={onEndDateOptionHandler}
                        className='BudgetForm_DateRadioGroup'
                    >
                        <Paper variant='outlined' className='BudgetForm_Radio limit'>
                            <FormControlLabel
                                value='never'
                                control={<Radio
                                    checkedIcon={<RadioButtonCheckedIcon
                                        className='BudgetForm_RadioCheckedIcon'/>}/>}
                                label='Never ends'
                            />
                        </Paper>
                        <Paper variant='outlined' className='BudgetForm_Radio limit'>
                            <FormControlLabel
                                value='on'
                                control={<Radio
                                    checkedIcon={<RadioButtonCheckedIcon
                                        className='BudgetForm_RadioCheckedIcon'/>}/>}
                                label='Ends on'
                            />
                        </Paper>
                    </RadioGroup>
                </FormControl>
            </Box>
        </Box>
        <Box className='BudgetForm_Buttons'>
            <Button variant='outlined' onClick={toggleDrawer(false, eventType)}>Cancel</Button>
            <Button variant='contained' onClick={onSetOrganizationLimitHandler}>Set {type === 'Group' ? 'team' : 'organisation'} limit</Button>
        </Box>
    </Stack>
}