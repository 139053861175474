import {AIModelID} from '../types/AiModel'

export const MODEL_COST_MAP_ZERO: Record<AIModelID,number> = {
    [AIModelID.GPT_3_5]: 0,
    [AIModelID.GPT_4]: 0,
    [AIModelID.GPT_4_TURBO]: 0,
    [AIModelID.GTP_4_O]: 0,
    [AIModelID.GPT_4_O_MINI]: 0,
    [AIModelID.O1]: 0,
    [AIModelID.O1_PREVIEW]: 0,
    [AIModelID.O1_MINI]: 0,
    [AIModelID.O3_MINI]: 0,
    [AIModelID.GOOGLE_GEMINI]: 0,
    [AIModelID.GOOGLE_GEMINI_1_5_FLASH]: 0,
    [AIModelID.GOOGLE_GEMINI_1_5_PRO]: 0,
    [AIModelID.GOOGLE_GEMINI_1_5_FLASH_002]: 0,
    [AIModelID.GOOGLE_GEMINI_1_5_PRO_002]: 0,
    [AIModelID.GOOGLE_GEMINI_2_0_FLASH]: 0,
    [AIModelID.GOOGLE_GEMINI_2_0_FLASH_LITE]: 0,
    [AIModelID.GOOGLE_GEMINI_2_0_PRO]: 0,
    [AIModelID.GOOGLE_GEMINI_2_5_PRO]: 0,
    [AIModelID.GOOGLE_GEMMA_2]: 0,
    [AIModelID.LLAMA_2]: 0,
    [AIModelID.LLAMA_3]: 0,
    [AIModelID.LLAMA_3_1]: 0,
    [AIModelID.LLAMA_3_2]: 0,
    [AIModelID.MISTRAL]: 0,
    [AIModelID.AMAZON_Q]: 0,
    [AIModelID.CLAUDE_3_HAIKU]: 0,
    [AIModelID.CLAUDE_3_5_HAIKU]: 0,
    [AIModelID.CLAUDE_3_5_SONNET]: 0,
    [AIModelID.CLAUDE_3_7_SONNET]: 0,
    [AIModelID.CLAUDE_3_OPUS]: 0
}