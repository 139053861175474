import React, {FC, useState} from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {Box, Button, CircularProgress, IconButton, TextField, Typography} from '@mui/material'
import {useAccountCreationContext} from '../../context/AccountCreationContext'
import {areCompanyDomainsValid, getCompanyDomain, isCompanyDomainValid} from '../../utils/accountCreationHelpers'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {validateOrganizationDomain} from '../../service/persistenceService'
import {useUserContext} from '../../context/UserContext'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CloseIcon from '@mui/icons-material/Close'

export const AccountCreationFormCompanyDomains: FC = () => {
    const {form, error, changeFormValue, changeFormValueValid, changeFormValueError} = useAccountCreationContext()
    const {companyDomains, companyName} = form
    const {user} = useUser()
    const [inputValue, setInputValue] = useState('')
    const [isValidating, setIsValidating] = useState(false)
    const [validationError, setValidationError] = useState('')
    const {token} = useUserContext()

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
        if (validationError) {
            setValidationError('')
        }
    }

    const handleAddDomainClicked = () => {
        const formattedDomain = getCompanyDomain(inputValue.trim())

        if (!formattedDomain || !isCompanyDomainValid(formattedDomain)) {
            setValidationError('Domain has to follow the example @yourcompany.com')
            return
        }

        if (companyDomains.includes(formattedDomain)) {
            setValidationError('This domain is already added')
            return
        }

        if (validationError) {
            setValidationError('')
        }

        setIsValidating(true)

        validateOrganizationDomain(token, formattedDomain)
            .then(() => {
                const updatedDomains = [...companyDomains, formattedDomain]
                changeFormValue('companyDomains', updatedDomains)
                changeFormValueValid('companyDomains', areCompanyDomainsValid(updatedDomains))
                setInputValue('')

                TrackActionEvent('Create Organization - Company Domain', user?.externalId ?? user?.id, {
                    company_name: companyName,
                    company_domains: updatedDomains,
                    action: 'add_domain',
                })
            })
            .catch(error => {
                setValidationError(error.message)
            })
            .finally(() => setIsValidating(false))
    }

    const handleDeleteDomain = (domain: string) => {
        const updatedDomains = companyDomains.filter((value) => value !== domain)
        changeFormValue('companyDomains', updatedDomains)
        changeFormValueValid('companyDomains', areCompanyDomainsValid(updatedDomains))

        TrackActionEvent('Create Organization - Company Domain', user?.externalId ?? user?.id, {
            company_name: companyName,
            company_domains: updatedDomains,
            action: 'remove_domain',
        })
    }

    return <Box className='AccountCreationForm_Control'>
        <Box>
            <Typography className='AccountCreationForm_ControlSubtitle'>
                Enter your company's domain to give colleagues access to your workspace.
            </Typography>
            <Typography className='AccountCreationForm_ControlCaption'>
                For example, @kolekti.com. Users still need to create accounts in Narus to access your workspace.
            </Typography>
        </Box>
        <Box className='AccountCreationForm_DomainInput'>
            <TextField
                className='AccountCreationForm_InputField'
                variant='outlined'
                placeholder='Eg. @yourcompany.com'
                value={inputValue}
                onChange={handleInputChange}
                error={!!validationError}
                helperText={validationError}
                fullWidth={true}
                size='small'
            />
            <Button className='AccountCreationForm_Action' variant='outlined' onClick={handleAddDomainClicked}
                    disabled={!inputValue.trim() || !!validationError}>
                {isValidating ? <CircularProgress size={22}/> : 'Add'}
            </Button>
        </Box>

        {companyDomains.length > 0 &&
            <Box className='AccountCreationForm_Domains'>
                {companyDomains.map((domain, index) => (
                    <Box key={`AccountCreationForm_Domain-${index}`}>
                        <Box className='AccountCreationForm_Domain'>
                            <Typography>{domain}</Typography>
                            <IconButton onClick={() => handleDeleteDomain(domain)}>
                                <DeleteOutlineIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                ))}
            </Box>
        }

        {error.companyDomains && <Box className='AccountCreationForm_DomainsError'>
            <Box className='AccountCreationForm_DomainsErrorWrapper'>
                <ErrorOutlineIcon className='AccountCreationForm_DomainsErrorIcon'/>
                <Typography className='AccountCreationForm_DomainsErrorMessage'>
                    Something went wrong. Please try again, or skip this step.
                </Typography>
            </Box>
            <CloseIcon className='AccountCreationForm_DomainsErrorCloseIcon'
                       onClick={() => changeFormValueError('companyDomains', undefined)}/>
        </Box>}
    </Box>
}