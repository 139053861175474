import {ChangeEvent, FC, useState} from 'react'
import {Box, Button, TextField, Typography} from '@mui/material'
import {useAccountCreationContext} from '../../context/AccountCreationContext'
import {isAdminEmailValid} from '../../utils/accountCreationHelpers'
import {useUser} from '@clerk/clerk-react'
import {AdminEmail} from '../admin/configuration/settings/AdminEmail'

export const AccountCreationFormAdminEmails: FC = () => {
	const {form, changeFormValue} = useAccountCreationContext()
	const {user} = useUser()
	const {adminEmails} = form

	const handleDeleteAdmin = (email: string) => {
		changeFormValue('adminEmails', adminEmails.filter(adminEmail => adminEmail !== email))
	}

	return <Box className='AccountCreationForm_Control'>
		<Typography variant='subtitle2' className='AccountCreationForm_ControlSubtitle'>
			Enter a user's email to grant admin access. Admins manage users, AI models, spending, and safeguarding.
		</Typography>

		<AccountCreationFormAdminEmailControl/>

		<Box className='AccountCreationForm_ListContainer'>
			<AdminEmail email={user?.emailAddresses[0].emailAddress ?? ''} deleteDisabled={true} handleDeleteClicked={() => {}}/>
			{adminEmails.map((email, index) =>
				<AdminEmail email={email} deleteDisabled={false} handleDeleteClicked={() => handleDeleteAdmin(email)} confirmDisabled={true} key={`adminEmail-${index}`}/>
			)}
		</Box>
	</Box>
}

const AccountCreationFormAdminEmailControl: FC = () => {
	const {form, error, changeFormValue} = useAccountCreationContext()
	const [validError, setValidError] = useState<boolean>(false)
	const {adminEmails, companyDomains} = form
	const [firstDomain] = companyDomains
	const showError = validError || !!error.adminEmails
	const helperText = showError ? (error.adminEmails || `Admin email has to follow the example ${firstDomain ?? '@example.com'}`) : ''

	const [emails, setEmails] = useState<string>('')

	const handleInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value
		setEmails(value)
		if (value === '') setValidError(false)
	}

	const handleKeyPress = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') handleAddAdmin()
	}

	const handleAddAdmin = () => {
		const emailsToAdd = emails.split(',').map(email => email.trim()).filter(email => email !== '')
		if(emailsToAdd.length > 0) {
			const validationError = emailsToAdd.some(email => !isAdminEmailValid(companyDomains)(email))
			setValidError(validationError)
			if (!validationError) {
				changeFormValue('adminEmails', [...new Set([...adminEmails, ...emailsToAdd])])
				setEmails('')
			}
		}
	}

	return <Box className='AccountCreationForm_AddAdminWrapper'>
		<Box className='AccountCreationForm_AddAdminContainer'>
			<TextField variant='outlined' error={showError} helperText={helperText} value={emails} className='AccountCreationForm_InputField' size='small'
			           placeholder='Write one or more emails' onChange={handleInputChanged} onKeyDown={handleKeyPress}/>
			<Button variant='outlined' className='AccountCreationForm_AddAdminButton' onClick={handleAddAdmin}>Add</Button>
		</Box>
		<Box hidden={showError}>
			<Typography className='AccountCreationForm_AddAdminHelp'>To add multiple users, list emails separated with a comma.</Typography>
		</Box>
	</Box>
}