import {FC, PropsWithChildren} from 'react'
import {useLocation} from 'react-router-dom'
import {Box} from '@mui/material'
import {FeedbackMessage} from '../feedbackMessage/FeedbackMessage'
import {MobileBanner} from '../mobileBanner/MobileBanner'
import {useAppMenuContext} from '../../context/AppMenuContext'
import {APP_MENU_SIDE_WIDTH, APP_MENU_TOTAL_WIDTH} from '../../constants/AppMenuConstants'
import '../../App.scss'

type Props = {
	hideBanners?: true
}

const getClassNameGridSuffix = (pathname: string): string => {
	if (pathname.startsWith('/admin')) {
		return 'admin'
	} else if (pathname.startsWith('/create-account')) {
		return 'account-creation'
	} else if (pathname.startsWith('/pricing')) {
		return 'pricing'
	} else if (pathname === '/') {
		return 'home'
	} else if (pathname.startsWith('/aiChat')) {
		return 'ai-chat'
	} else if (pathname.startsWith('/prompt')) {
		return 'prompt'
	} else {
		return ''
	}
}

const hasChat = (pathname: string): boolean =>
	pathname.startsWith('/aiChat') || pathname === '/' || pathname.startsWith('/prompt')

export const AppGridWrapper: FC<PropsWithChildren<Props>> = ({children, hideBanners}) => {
	const {isOpen} = useAppMenuContext()
	const location = useLocation()

	const classnameGridSuffix = getClassNameGridSuffix(location.pathname)

	const isChatPage = hasChat(location.pathname)
	const menuOffset = isOpen ? `${APP_MENU_TOTAL_WIDTH / 2}px` : `${(APP_MENU_SIDE_WIDTH + 16) / 2}px`
	const feedbackLeft = isChatPage ? menuOffset : undefined

    return <Box className={`App ${classnameGridSuffix}`}>
        { children }
		{ !hideBanners ? <>
            <FeedbackMessage left={feedbackLeft}/>
            <MobileBanner/>
		</> : <></>}
    </Box>
}