import {Budget, BudgetPeriodicity, CustomPeriodicity} from '../types/Budget'
import {getClosestDailyPeriodDate} from './dateUtils'
import {BUDGET_PERIODICITY_MULTIPLIERS} from '../constants/BudgetConstants'

export const getClosestBudgetPeriodStartDate = (budget: Budget): Date =>
    getClosestDailyPeriodDate(new Date(budget.startDate), budget.periodicityInDays)

export const getClosestBudgetPeriodEndDate = (budget: Budget): Date => {
    const startDate = getClosestBudgetPeriodStartDate(budget)
    const endDate = new Date(startDate)
    endDate.setDate(endDate.getDate() + budget.periodicityInDays)
    return endDate
}

export const getPeriodicityInDays = (budgetPeriodicity: BudgetPeriodicity): number =>
    isCustomBudgetPeriodicity(budgetPeriodicity)
        ? BUDGET_PERIODICITY_MULTIPLIERS[budgetPeriodicity.type][budgetPeriodicity.customType] * budgetPeriodicity.unit
        : BUDGET_PERIODICITY_MULTIPLIERS[budgetPeriodicity.type]

export const isCustomBudgetPeriodicity = (budgetPeriodicity: BudgetPeriodicity): budgetPeriodicity is CustomPeriodicity =>
    budgetPeriodicity.type === 'Custom' && !!budgetPeriodicity.customType