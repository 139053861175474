import {MODEL_COST_MAP_ZERO} from '../constants/AIModelConstants'
import {AIModelID} from '../types/AiModel'
import {AiPricing} from '../types/AiPricing'
import {AuditInfoStored, ModelAuditMap, UserAuditMap} from '../types/AuditInfo'
import {Group} from '../types/Group'
import {GroupMember} from '../types/GroupMember'
import {UserInfoMap} from '../types/UserInfo'
import {GroupUsageStatistic, UsageStatistic, UserUsageStatistic} from '../types/UserUsageStatistic'
import {getModelAuditMap, getModelAuditMapCost, getUserAuditMap} from './auditInfoUtils'

export const getUserUsageStatistics = (
    aiPricing: AiPricing[], 
    executionAuditLogs: AuditInfoStored[], 
    userInfoMap: UserInfoMap,
    groups: Group[],
    allGroupMembers: GroupMember[]
): UsageStatistic => {

    const userAuditMap: UserAuditMap = getUserAuditMap(executionAuditLogs)

    let totalCostByModel = {...MODEL_COST_MAP_ZERO}
    let totalCostAllModels = 0

    const userUsageStatistics: UserUsageStatistic[] = Object.entries(userAuditMap).map<UserUsageStatistic>(([userId, userAuditLogs]) => {
        const modelAuditMap = getModelAuditMap(userAuditLogs)
        const { modelCostMap, total } = getModelAuditMapCost(modelAuditMap, aiPricing)
        totalCostAllModels += total
        Object.entries(modelCostMap).forEach(([modelId, cost]) => totalCostByModel[modelId] += cost)
        const totalPrompts: number = Object.entries(modelAuditMap)
            .filter(([modelId]) => modelId !== AIModelID.AMAZON_Q)
            .map(([,logs]) => logs.length)
            .reduce((acc, curr) => acc + curr, 0)
        const totalPromptsByModel: Record<AIModelID, number> = {
            [AIModelID.GPT_3_5]: getModelTotalPromptsByModelAuditMap(AIModelID.GPT_3_5, modelAuditMap),
            [AIModelID.GPT_4]: getModelTotalPromptsByModelAuditMap(AIModelID.GPT_4, modelAuditMap),
            [AIModelID.GPT_4_TURBO]: getModelTotalPromptsByModelAuditMap(AIModelID.GPT_4_TURBO, modelAuditMap),
            [AIModelID.GTP_4_O]: getModelTotalPromptsByModelAuditMap(AIModelID.GTP_4_O, modelAuditMap),
            [AIModelID.GPT_4_O_MINI]: getModelTotalPromptsByModelAuditMap(AIModelID.GPT_4_O_MINI, modelAuditMap),
            [AIModelID.O1]: getModelTotalPromptsByModelAuditMap(AIModelID.O1, modelAuditMap),
            [AIModelID.O1_PREVIEW]: getModelTotalPromptsByModelAuditMap(AIModelID.O1_PREVIEW, modelAuditMap),
            [AIModelID.O1_MINI]: getModelTotalPromptsByModelAuditMap(AIModelID.O1_MINI, modelAuditMap),
            [AIModelID.O3_MINI]: getModelTotalPromptsByModelAuditMap(AIModelID.O3_MINI, modelAuditMap),
            [AIModelID.GOOGLE_GEMINI]: getModelTotalPromptsByModelAuditMap(AIModelID.GOOGLE_GEMINI, modelAuditMap),
            [AIModelID.GOOGLE_GEMINI_1_5_FLASH]: getModelTotalPromptsByModelAuditMap(AIModelID.GOOGLE_GEMINI_1_5_FLASH, modelAuditMap),
            [AIModelID.GOOGLE_GEMINI_1_5_PRO]: getModelTotalPromptsByModelAuditMap(AIModelID.GOOGLE_GEMINI_1_5_PRO, modelAuditMap),
            [AIModelID.GOOGLE_GEMINI_1_5_FLASH_002]: getModelTotalPromptsByModelAuditMap(AIModelID.GOOGLE_GEMINI_1_5_FLASH_002, modelAuditMap),
            [AIModelID.GOOGLE_GEMINI_1_5_PRO_002]: getModelTotalPromptsByModelAuditMap(AIModelID.GOOGLE_GEMINI_1_5_PRO_002, modelAuditMap),
            [AIModelID.GOOGLE_GEMINI_2_0_FLASH]: getModelTotalPromptsByModelAuditMap(AIModelID.GOOGLE_GEMINI_2_0_FLASH, modelAuditMap),
            [AIModelID.GOOGLE_GEMINI_2_0_FLASH_LITE]: getModelTotalPromptsByModelAuditMap(AIModelID.GOOGLE_GEMINI_2_0_FLASH_LITE, modelAuditMap),
            [AIModelID.GOOGLE_GEMINI_2_0_PRO]: getModelTotalPromptsByModelAuditMap(AIModelID.GOOGLE_GEMINI_2_0_PRO, modelAuditMap),
            [AIModelID.GOOGLE_GEMINI_2_5_PRO]: getModelTotalPromptsByModelAuditMap(AIModelID.GOOGLE_GEMINI_2_5_PRO, modelAuditMap),
            [AIModelID.GOOGLE_GEMMA_2]: getModelTotalPromptsByModelAuditMap(AIModelID.GOOGLE_GEMMA_2, modelAuditMap),
            [AIModelID.LLAMA_2]: getModelTotalPromptsByModelAuditMap(AIModelID.LLAMA_2, modelAuditMap),
            [AIModelID.LLAMA_3]: getModelTotalPromptsByModelAuditMap(AIModelID.LLAMA_3, modelAuditMap),
            [AIModelID.LLAMA_3_1]: getModelTotalPromptsByModelAuditMap(AIModelID.LLAMA_3_1, modelAuditMap),
            [AIModelID.LLAMA_3_2]: getModelTotalPromptsByModelAuditMap(AIModelID.LLAMA_3_2, modelAuditMap),
            [AIModelID.MISTRAL]: getModelTotalPromptsByModelAuditMap(AIModelID.MISTRAL, modelAuditMap),
            [AIModelID.AMAZON_Q]: 0,
            [AIModelID.CLAUDE_3_HAIKU]: getModelTotalPromptsByModelAuditMap(AIModelID.CLAUDE_3_HAIKU, modelAuditMap),
            [AIModelID.CLAUDE_3_5_HAIKU]: getModelTotalPromptsByModelAuditMap(AIModelID.CLAUDE_3_5_HAIKU, modelAuditMap),
            [AIModelID.CLAUDE_3_5_SONNET]: getModelTotalPromptsByModelAuditMap(AIModelID.CLAUDE_3_5_SONNET, modelAuditMap),
            [AIModelID.CLAUDE_3_7_SONNET]: getModelTotalPromptsByModelAuditMap(AIModelID.CLAUDE_3_7_SONNET, modelAuditMap),
            [AIModelID.CLAUDE_3_OPUS]: getModelTotalPromptsByModelAuditMap(AIModelID.CLAUDE_3_OPUS, modelAuditMap)
        }

        return {
            userId,
            userFullName: userInfoMap[userId]?.userFullName ?? '',
            totalPrompts,
            totalPromptsByModel,
            totalCost: total,
            currency: 'USD'
        }
    })

    return {
        userUsageStatistics,
        groupUsageStatistics: groupUsageStatisticsByGroups(groups, allGroupMembers, userUsageStatistics),
        modelCostStatistics: {totalCostByModel, totalCostAllModels }
    }
}

export const groupUsageStatisticsByGroups = (groups: Group[], allGroupMembers: GroupMember[], userUsageStatistics: UserUsageStatistic[]): GroupUsageStatistic[] => {
	if (!groups.length) return []

	const statisticsByGroups =  groups.map(group => {
		const userIdsInGroup = allGroupMembers
			.filter(member => member.groupId === group.hashKey)
			.map(member => member.userId)

		const userInGroupUsageStatistics = userUsageStatistics.filter(userUsageStatistic => userIdsInGroup.includes(userUsageStatistic.userId) )

		return getStatisticsByGroup(group.hashKey, group.name, userInGroupUsageStatistics)
	})

	const usersIdsInGroups = allGroupMembers.map(member => member.userId)
	const userUsageStatisticsForUsersWithoutGroup = userUsageStatistics.filter(userUsageStatistics => !usersIdsInGroups.includes(userUsageStatistics.userId))
	const statisticsForUsersWithoutGroup = getStatisticsByGroup('no-group', 'Unassigned', userUsageStatisticsForUsersWithoutGroup)
	return [...statisticsByGroups, statisticsForUsersWithoutGroup].filter(groupUsageStatistics => groupUsageStatistics) as GroupUsageStatistic[]
}

export const getStatisticsByGroup = (groupId: string, groupName: string, userInGroupUsageStatistics: UserUsageStatistic[]): GroupUsageStatistic | undefined  => {
	if (!userInGroupUsageStatistics.length) return
	return {
		groupId,
		groupName: groupName,
		totalPrompts: userInGroupUsageStatistics.reduce((sum, current) => sum + current.totalPrompts, 0),
		totalPromptsByModel: {
			[AIModelID.GPT_3_5]: getModelTotalPromptsByUsersUsage(AIModelID.GPT_3_5, userInGroupUsageStatistics),
			[AIModelID.GPT_4]: getModelTotalPromptsByUsersUsage(AIModelID.GPT_4, userInGroupUsageStatistics),
			[AIModelID.GPT_4_TURBO]: getModelTotalPromptsByUsersUsage(AIModelID.GPT_4_TURBO, userInGroupUsageStatistics),
			[AIModelID.GTP_4_O]: getModelTotalPromptsByUsersUsage(AIModelID.GTP_4_O, userInGroupUsageStatistics),
			[AIModelID.GPT_4_O_MINI]: getModelTotalPromptsByUsersUsage(AIModelID.GPT_4_O_MINI, userInGroupUsageStatistics),
			[AIModelID.O1]: getModelTotalPromptsByUsersUsage(AIModelID.O1, userInGroupUsageStatistics),
			[AIModelID.O1_PREVIEW]: getModelTotalPromptsByUsersUsage(AIModelID.O1_PREVIEW, userInGroupUsageStatistics),
			[AIModelID.O1_MINI]: getModelTotalPromptsByUsersUsage(AIModelID.O1_MINI, userInGroupUsageStatistics),
			[AIModelID.O3_MINI]: getModelTotalPromptsByUsersUsage(AIModelID.O3_MINI, userInGroupUsageStatistics),
			[AIModelID.GOOGLE_GEMINI]: getModelTotalPromptsByUsersUsage(AIModelID.GOOGLE_GEMINI, userInGroupUsageStatistics),
			[AIModelID.GOOGLE_GEMINI_1_5_FLASH]: getModelTotalPromptsByUsersUsage(AIModelID.GOOGLE_GEMINI_1_5_FLASH, userInGroupUsageStatistics),
			[AIModelID.GOOGLE_GEMINI_1_5_PRO]: getModelTotalPromptsByUsersUsage(AIModelID.GOOGLE_GEMINI_1_5_PRO, userInGroupUsageStatistics),
			[AIModelID.GOOGLE_GEMINI_1_5_FLASH_002]: getModelTotalPromptsByUsersUsage(AIModelID.GOOGLE_GEMINI_1_5_FLASH_002, userInGroupUsageStatistics),
			[AIModelID.GOOGLE_GEMINI_1_5_PRO_002]: getModelTotalPromptsByUsersUsage(AIModelID.GOOGLE_GEMINI_1_5_PRO_002, userInGroupUsageStatistics),
      [AIModelID.GOOGLE_GEMINI_2_0_FLASH]: getModelTotalPromptsByUsersUsage(AIModelID.GOOGLE_GEMINI_2_0_FLASH, userInGroupUsageStatistics),
      [AIModelID.GOOGLE_GEMINI_2_0_FLASH_LITE]: getModelTotalPromptsByUsersUsage(AIModelID.GOOGLE_GEMINI_2_0_FLASH_LITE, userInGroupUsageStatistics),
      [AIModelID.GOOGLE_GEMINI_2_0_PRO]: getModelTotalPromptsByUsersUsage(AIModelID.GOOGLE_GEMINI_2_0_PRO, userInGroupUsageStatistics),
      [AIModelID.GOOGLE_GEMINI_2_5_PRO]: getModelTotalPromptsByUsersUsage(AIModelID.GOOGLE_GEMINI_2_5_PRO, userInGroupUsageStatistics),
			[AIModelID.GOOGLE_GEMMA_2]: getModelTotalPromptsByUsersUsage(AIModelID.GOOGLE_GEMMA_2, userInGroupUsageStatistics),
			[AIModelID.LLAMA_2]: getModelTotalPromptsByUsersUsage(AIModelID.LLAMA_2, userInGroupUsageStatistics),
			[AIModelID.LLAMA_3]: getModelTotalPromptsByUsersUsage(AIModelID.LLAMA_3, userInGroupUsageStatistics),
			[AIModelID.LLAMA_3_1]: getModelTotalPromptsByUsersUsage(AIModelID.LLAMA_3_1, userInGroupUsageStatistics),
			[AIModelID.LLAMA_3_2]: getModelTotalPromptsByUsersUsage(AIModelID.LLAMA_3_2, userInGroupUsageStatistics),
			[AIModelID.MISTRAL]: getModelTotalPromptsByUsersUsage(AIModelID.MISTRAL, userInGroupUsageStatistics),
			[AIModelID.AMAZON_Q]: 0,
			[AIModelID.CLAUDE_3_HAIKU]: getModelTotalPromptsByUsersUsage(AIModelID.CLAUDE_3_HAIKU, userInGroupUsageStatistics),
      [AIModelID.CLAUDE_3_5_HAIKU]: getModelTotalPromptsByUsersUsage(AIModelID.CLAUDE_3_5_HAIKU, userInGroupUsageStatistics),
			[AIModelID.CLAUDE_3_5_SONNET]: getModelTotalPromptsByUsersUsage(AIModelID.CLAUDE_3_5_SONNET, userInGroupUsageStatistics),
			[AIModelID.CLAUDE_3_7_SONNET]: getModelTotalPromptsByUsersUsage(AIModelID.CLAUDE_3_7_SONNET, userInGroupUsageStatistics),
      [AIModelID.CLAUDE_3_OPUS]: getModelTotalPromptsByUsersUsage(AIModelID.CLAUDE_3_OPUS, userInGroupUsageStatistics)
		},
		totalCost: userInGroupUsageStatistics.reduce((sum, current) => sum + current.totalCost, 0),
		currency: 'USD',
	}
}
export const getModelTotalPromptsByModelAuditMap = (aiModelId: AIModelID, modelAuditMap: ModelAuditMap): number =>
    modelAuditMap[aiModelId]?.length ?? 0

export const getModelTotalPromptsByUsersUsage = (aiModelId: AIModelID, userUsageStatistics: UserUsageStatistic[]): number =>
    userUsageStatistics.reduce((sum, current) => sum + current.totalPromptsByModel[aiModelId], 0)