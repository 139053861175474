import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {Alert, Box, Button, InputAdornment, Stack, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PaymentsIcon from '@mui/icons-material/PaymentsOutlined'
import {useBudgetsContext} from '../../../../context/BudgetContext'
import {formatDateStringToLocaleDate} from '../../../../helpers/DateHelpers'
import TextField from '@mui/material/TextField'
import React, {ChangeEvent, useState} from 'react'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import './EditBudget.scss'
import {BudgetType} from '../../../../types/Budget'

type EditBudgetProps = {
  type: BudgetType
}

export const EditBudget = ({type}: EditBudgetProps) => {
    const {toggleDrawer} = useToggleDrawerContext()
    const {overallBudget, updateBudgetAmount, groupsWithBudgets, selectedGroupId} = useBudgetsContext()
    const {user} = useUser()

    const eventType = type === 'Organization' ? 'EDIT_OVERALL_BUDGET' : 'EDIT_TEAM_BUDGET'
    const groupBudget = groupsWithBudgets.find(group => group.hashKey === selectedGroupId)?.budget
    const budget = type === 'Organization' ? overallBudget! : groupBudget!
    const [amount, setAmount] = useState<string>(budget.amount.toString())
    const [amountValidationError, setAmountValidationError] = useState<string>()

    const handleUpdateBudgetLimit = async (event: React.MouseEvent) => {
        const updatedAmount = parseFloat(amount)

        if (type === 'Group' && updatedAmount > overallBudget!.amount) return
        TrackActionEvent('Budget', user?.externalId ?? user?.id, {action: 'update', type})
        const updatedBudget = await updateBudgetAmount(budget, updatedAmount)
        if (updatedBudget !== undefined) toggleDrawer(false, eventType)(event)
    }

    const handleUpdateAmount = (event: ChangeEvent<HTMLInputElement>) => {
        const parsedAmount = parseFloat(event.target.value)
        setAmount(event.target.value)

        if (isNaN(parsedAmount) || parsedAmount <= 0) {
            setAmountValidationError('Amount must be a number greater than 0')
            return
        }

        if (type === 'Group' && parsedAmount > overallBudget!.amount) {
            setAmountValidationError('Team limit can\'t be higher than the organization limit')
            return
        }

        setAmountValidationError(undefined)
    }

    return <Stack className='editBudget_Container'>
        <Box>
            <Box className='editBudget_TitleContainer'>
                <Box className='editBudget_Title'>
                    <Box className='editBudget_TitleIcon'>
                        <PaymentsIcon fontSize='small'/>
                    </Box>
                    <Typography variant='h4'>{type === 'Group' ? 'Team' : 'Organisation'} limit</Typography>
                </Box>
                <CloseIcon onClick={toggleDrawer(false, eventType)} cursor='pointer'
                           className='editBudget_CloseIcon'/>
            </Box>
            <Box>
                <Typography className='editBudget_Subtitle'>Spending limit</Typography>
            </Box>

            <Box>
                <Typography variant="body2" className='editBudget_AmountLabel'>Amount</Typography>
                <TextField fullWidth required onChange={handleUpdateAmount} error={!!amountValidationError} size='small'
                           InputProps={{startAdornment: <InputAdornment position='start'>$</InputAdornment>}}
                           helperText={amountValidationError}
                           value={amount}/>
                <Alert severity='info' className='editBudget_CostAlert'>Costs are estimated. Actual bill may vary.</Alert>
            </Box>

            <Box>
                <Typography className='editBudget_SpendingLimit'>Spending limit cycle: <span className='editBudget_Periodicity'>{budget.periodicity}</span></Typography>
            </Box>
            <Box>
                <Typography className='editBudget_EndDate'>End date: <span className='editBudget_Date'>{budget.endDate === new Date('9999-12-31').getTime() ? 'Never' : formatDateStringToLocaleDate(budget.endDate)}</span></Typography>
            </Box>
            <Box>
                <Alert severity='warning' className='editBudget_CycleAlert'>To change the limit cycle, remove the current limit and set up a new one.</Alert>
            </Box>
        </Box>
        <Box className='editBudget_Buttons'>
            <Button variant='outlined' onClick={toggleDrawer(false, eventType)}>Cancel</Button>
            <Button variant='contained' onClick={handleUpdateBudgetLimit}>Update</Button>
        </Box>
    </Stack>
}