import {useCallback, useEffect, useState} from 'react'
import {Box, IconButton, Tab, Tabs, Typography} from '@mui/material'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {Location, useNavigate} from 'react-router-dom'
import {useAbortController} from '../../hooks/useAbortController'
import {usePromptCreationContext} from '../../context/PromptCreationContext'
import {AIModelID} from '../../types/AiModel'
import {AiModelIcon} from '../icons/AiModelIcon'
import {Chat} from './Chat'
import {PromptChatDetail} from '../../types/PromptChatDetail'
import {removeChatHistoryForPromptAndModel} from '../../service/persistenceService'
import {useUserContext} from '../../context/UserContext'
import {AiChatProps} from '../../types/AiChatProps'
import {v4 as uuidv4} from 'uuid'
import {useFeedbackContext} from '../../context/FeedbackContext'
import {useAiModelsContext} from '../../context/AIModelsContext'
import {CustomTaskBar} from './TaskBar'
import {useChatsContext} from '../../context/ChatsContext'
import {AiAdvancedSettingsDialog} from '../aiAdvancedSettings/AiAdvancedSettingsDialog'
import {useChatMessagesContext} from '../../context/ChatMessagesContext'
import {useSearchContext} from '../../context/SearchContext'
import {getModelId, getSelectedModelIds} from '../../utils/aiModelUtils'
import {isContained, not} from '../../utils/genericUtils'
import {DialogParams, useAppNavigationContext} from '../../context/AppNavigationContext'
import {useFilesContext} from '../../context/FilesContext'
import {useLocation} from 'react-router'
import {FileUpload} from '../../types/File'
import BackIcon from '@mui/icons-material/ArrowBack'
import './AiChat.scss'
import SaveSummarisedPrompt from '../saveSummarisedPrompt/SaveSummarisedPrompt'

export const AiChat = ({selectedPrompt, isPromptOptimized, skipInitialExecution}: AiChatProps) => {

	const navigate = useNavigate()
	const location = useLocation()
	const appNavigationContext = useAppNavigationContext()
	const {
		addNavigationDialog,
		removeNavigationDialog,
		addBeforeNavigationCallback,
		removeBeforeNavigationCallback,
		addUnhandledNavigationCallback,
		removeUnhandledNavigationCallback
	} = appNavigationContext
	const appNavigate = appNavigationContext.navigate
	const {token} = useUserContext()
	const {clearFiles, files} = useFilesContext()

	const {modelsSelected} = useSearchContext()
	const {
		promptId, userPrompt, aiOutputLoading, setAiOutput, tone, audience, setUserPrompt,
		format, temperature, language, frequencyPenalty, promptChats, unsavedChanges, clearPromptOptimizationFields, setModelId,
		getAiOutput, getPromptTemplate, setAiOutputLoading, clearPromptFields, setPromptChats, setUnsavedChanges, setAiPromptLoading, getAiGeneratedPrompt
	} = usePromptCreationContext()

	const {user} = useUser()
	const {abortControllerRefSignal, abortControllerRefAbort} = useAbortController()
	const {showFeedback} = useFeedbackContext()
	const {aiModelMap} = useAiModelsContext()
	const {clearChatData, clearChatFields, chatIdsForCurrentConversation} = useChatsContext()
	const {chatId, setChatMessages} = useChatMessagesContext()

	const [selectedTab, setSelectedTab] = useState<number>(0)
	const [initialPromptGenerationDone, setInitialPromptGenerationDone] = useState<boolean>(skipInitialExecution ?? false)
	const [tempPromptId] = useState<string>(uuidv4())
	const [showSliders, setShowSliders] = useState<boolean>(false)
	const [showSavePromptDialog, setShowSavePromptDialog] = useState(false)

	const selectedPromptUserID = selectedPrompt?.userId
	const isPromptOwnedByOther = !!(selectedPromptUserID && (user?.externalId ?? user?.id) && selectedPromptUserID !== (user.externalId ?? user.id))
	const isPromptOptimizedValid = !!((tone || audience || language || format) && userPrompt)
	const isSavedChat = !!location.pathname.split('/')?.[2]
	const isAllNewChatsSaved = !promptChats.some(({ modelId }) => {
		const savedChatsModelIds = chatIdsForCurrentConversation.map(chat => chat.modelId)
		return !savedChatsModelIds.includes(modelId)
	})

	const generateAiOutputHandler = useCallback(async (modelId: AIModelID, isRegeneratedMessage: boolean, userPromptFromInitial?: string, previousMessages?: string[], files?: FileUpload[]) => {
		if (aiOutputLoading || (!userPrompt && !userPromptFromInitial)) return
		setAiOutput('')
		return getAiOutput({
			...getPromptTemplate(),
			modelId,
			userPrompt: userPromptFromInitial! || userPrompt,
			tempPromptId,
		}, abortControllerRefSignal, isRegeneratedMessage, promptId, previousMessages, files).then(aiOutput => {
			setAiOutput(aiOutput.aiOutput)
			TrackActionEvent('Run prompt', user?.externalId ?? user?.id, {
				action: 'run_ai_prompt',
				prompt_id: promptId,
				model: modelId,
				tone,
				audience,
				format,
				temperature,
				language,
				frequency_penalty: frequencyPenalty
			})
		}).catch((error) => {
			if (error.name !== 'AbortError') {
				setAiOutputLoading(false)
			}
		})
	}, [getAiOutput, getPromptTemplate, promptId, setAiOutput, aiOutputLoading, abortControllerRefSignal, tone, audience, format, user?.id, user?.externalId, frequencyPenalty, temperature, language, userPrompt, tempPromptId, setAiOutputLoading])

	const generateInitialPrompt = useCallback(async () => {
		const generateAllInitialModelResponses = promptChats.map(({modelId, messages}: PromptChatDetail) => {
			if (messages.length > 0) return generateAiOutputHandler(modelId, false, messages[0].text)
			return undefined
		}).filter(promise => promise) as Promise<void>[]
		await Promise.all(generateAllInitialModelResponses)
		setAiOutputLoading(false)
	}, [promptChats, generateAiOutputHandler, setAiOutputLoading])

	const optimizedPromptHandler = useCallback(async (modelIdInput?: AIModelID) => {
		const originalUserPrompt = userPrompt
		setUserPrompt('')
		const modelIds = modelIdInput ? [{modelId: modelIdInput}] : promptChats.map(({modelId}) => ({modelId}))
		try {
			setAiPromptLoading(true)
			await Promise.all(modelIds.map(async ({modelId}) => {
				const newTempPromptId = uuidv4()
				const aiGeneratedPrompt = await getAiGeneratedPrompt({
					...getPromptTemplate(), modelId, tempPromptId: newTempPromptId
				}, abortControllerRefSignal, promptId)
				if (aiGeneratedPrompt) {
					clearPromptOptimizationFields()
					return generateAiOutputHandler(modelId, false, aiGeneratedPrompt.aiOutput, [], files)
				}
			}))
		} catch (error) {
			TrackActionEvent('Error', user?.externalId ?? user?.id, {
				origin: 'optimized_prompt',
				error
			})
			showFeedback('Error', 'The AI model selected could not return a response. Please try again or use a different model.', 'error', 10)
			setUserPrompt(originalUserPrompt)
		} finally {
			clearPromptOptimizationFields()
		}
	}, [setAiPromptLoading, getAiGeneratedPrompt, getPromptTemplate, abortControllerRefSignal, promptChats, generateAiOutputHandler, promptId,
		setUserPrompt, showFeedback, userPrompt, user?.id, user?.externalId, clearPromptOptimizationFields, files])

	useEffect(() => {
		if (!initialPromptGenerationDone && promptChats.length) {
			if (isPromptOptimized && isPromptOptimizedValid) optimizedPromptHandler()
			else if (isPromptOptimized && !isPromptOptimizedValid) navigate('/aiChat')
			else generateInitialPrompt()
			setInitialPromptGenerationDone(true)
			clearFiles()
		}
	}, [initialPromptGenerationDone, generateInitialPrompt, promptChats, optimizedPromptHandler, isPromptOptimized, isPromptOptimizedValid, navigate, clearFiles])

	const removeChatHistory = useCallback(() => {
		promptChats.forEach(({modelId}: PromptChatDetail) => {
			removeChatHistoryForPromptAndModel(promptId || tempPromptId, modelId, token)
		})
	}, [promptChats, promptId, tempPromptId, token])


	const changeTabHandler = (_: React.SyntheticEvent, tabIndex: number) => {
		setSelectedTab(tabIndex)
		setModelId(promptChats[tabIndex].modelId)
	}

	const handleDiscardDismiss = useCallback(() => {
		let extraInfoAnalytics = { action: 'discard' }
		if (promptId) extraInfoAnalytics['prompt_id'] = promptId
		if (chatId) extraInfoAnalytics['chat_id'] = chatId
		TrackActionEvent('Dismiss', user?.externalId ?? user?.id, extraInfoAnalytics)
	}, [user, promptId, chatId])

	const cleanup = useCallback(() => {
		setUnsavedChanges(false)
		abortControllerRefAbort()
		removeChatHistory()
		clearPromptFields()
		clearChatData()
		clearChatFields()
		setPromptChats([])
		clearFiles()
		setChatMessages([])
	}, [abortControllerRefAbort, removeChatHistory, clearPromptFields, clearChatData, setPromptChats, setChatMessages, setUnsavedChanges, clearChatFields, clearFiles])

	const handleExit = useCallback(() => {
		cleanup()
		navigate('/')
	}, [cleanup, navigate])

	const handleGoBackClick = useCallback(() => {
		let extraInfoAnalytics = {
			action: 'go_back'
		}
		if (promptId) extraInfoAnalytics['prompt_id'] = promptId
		if (chatId) extraInfoAnalytics['chat_id'] = chatId
		TrackActionEvent('Dismiss', user?.externalId ?? user?.id, extraInfoAnalytics)
		appNavigate('/', cleanup)
	}, [promptId, chatId, user, appNavigate, cleanup])

	useEffect(() => {
		window.addEventListener('beforeunload', cleanup)
		return () => {
			window.removeEventListener('beforeunload', cleanup)
		}
	}, [cleanup])

	useEffect(() => {
		const selectedIds = getSelectedModelIds(modelsSelected)
		if (selectedIds.length && !selectedPrompt) {
			setPromptChats(previousChatPrompts => {
				const newChatPrompts = selectedIds
					.map(modelId => ({ modelId, messages: [], compilationSummary: {} }))
					.filter(not(isContained(previousChatPrompts, getModelId)))

				return newChatPrompts.length ? [...previousChatPrompts, ...newChatPrompts] : previousChatPrompts
			})
		}
	}, [modelsSelected, setPromptChats, selectedPrompt])

	// Determines whether the user can freely navigate out of the current page or not
	useEffect(() => {
		const showDialogOnNavigate = (!isAllNewChatsSaved && !isSavedChat) || unsavedChanges
		const dialogParams: DialogParams | undefined = showDialogOnNavigate ? {
            title: 'Are you sure you want to leave the chat?',
            text: 'Careful! This chat won’t be saved if you go back to the homepage. Make sure to copy the output you need before continuing.',
            primaryText: 'Continue',
            secondaryText: 'Stay here',
			onClose: handleDiscardDismiss,
			onPrimary: () => {},
			onSecondary: handleDiscardDismiss
		} : undefined
		if (dialogParams) {
			addNavigationDialog(dialogParams)
		}

		return () => {
			if (dialogParams) {
				removeNavigationDialog(dialogParams)
			}
		}
	}, [promptId, chatId, promptChats, unsavedChanges, user, handleDiscardDismiss, addNavigationDialog, removeNavigationDialog, isAllNewChatsSaved, isSavedChat])

	// Ensure app navigation does cleanup on exit
	useEffect(() => {
		addBeforeNavigationCallback(cleanup)

		return () => {
			removeBeforeNavigationCallback(cleanup)
		}
	}, [cleanup, addBeforeNavigationCallback, removeBeforeNavigationCallback])

	// Ensure unhandled navigation (browser back and forward) does hard cleanup on exit
	useEffect(() => {
		const callback = (from: Location, to: Location) => {
			if (from.pathname.startsWith('/aiChat') && !to.pathname.startsWith('/aiChat')) {
				cleanup()
			}
		}
		addUnhandledNavigationCallback(callback)

		return () => {
			removeUnhandledNavigationCallback(callback)
		}
	}, [cleanup, addUnhandledNavigationCallback, removeUnhandledNavigationCallback])

	return <>
		<Box className='AiChat_Container'>
			<Box className='AiChat_HeaderContainer'>
				<Box className='AiChat_HeaderLine'/>
				<Box className='AiChat_HeaderContent'>
					<IconButton className='AiChat_Back' size='small' onClick={handleGoBackClick}>
						<BackIcon/>
					</IconButton>
					<Tabs className='AiChat_Tabs' scrollButtons='auto' variant='scrollable' value={selectedTab} onChange={changeTabHandler}>
						{promptChats.map(({ modelId }, index) =>
							<Tab key={`tab-${modelId}`} 
								label={aiModelMap[modelId]?.name}
								icon={<AiModelIcon modelId={modelId} className={selectedTab !== index ? 'inactive' : ''}/>}
								iconPosition='start'/>
						)}
					</Tabs>
				</Box>
			</Box>
			{ promptChats[selectedTab]
				? <Box className='AiChat_Content'>
					<CustomTaskBar promptChatDetails={promptChats[selectedTab]}
						isPromptOwnedByOther={isPromptOwnedByOther}
						onOpenAdvancedSettings={() => setShowSliders(true)}
					 	onSavePromptClicked={() => setShowSavePromptDialog(true)}
          />
					<Chat promptChatDetails={promptChats[selectedTab]}
						generateAiOutputHandler={generateAiOutputHandler}
						tempPromptId={tempPromptId!}
						optimizedPromptHandler={optimizedPromptHandler}
					/>
					<SaveSummarisedPrompt promptChatDetails={promptChats[selectedTab]}
						tempPromptId={tempPromptId!}
						onUpdate={handleExit}
						onClose={() => setShowSavePromptDialog(false)}
						open={showSavePromptDialog}
					/>
					<Typography className='AiChat_Quote'>{aiModelMap[promptChats[selectedTab].modelId]?.name} can make mistakes. Check important info.</Typography>
				</Box>
				: <></>}
		</Box>
		<AiAdvancedSettingsDialog showSliders={showSliders} setShowSliders={setShowSliders}/>
	</>
}