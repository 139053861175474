import React, {useState} from 'react'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {Box, Divider, ListItemIcon, Menu, MenuItem, Typography} from '@mui/material'
import {useBudgetsContext} from '../../../../context/BudgetContext'
import {GridActionsCellItem} from '@mui/x-data-grid'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined'
import {formatAmount} from '../../../../utils/currencyUtils'
import {formatDateToLocaleDate} from '../../../../helpers/DateHelpers'
import {getClosestBudgetPeriodEndDate} from '../../../../utils/budgetUtils'
import {Budget} from '../../../../types/Budget'
import ConfirmationDialog from '../../../promptForm/confirmationDialog/ConfirmationDialog'
import {DrawerType} from '../../../../hooks/useToggleDrawer'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'

export type TeamBudgetProps = {
    groupId: string
    budget?: Budget
}

export const TeamBudgetActions = ({groupId, budget}: TeamBudgetProps) => {
    const [teamAnchorEl, setTeamAnchorEl] = useState<null | HTMLElement>(null)
    const [showRemoveTeamLimitDialog, setShowRemoveTeamLimitDialog] = useState<boolean>(false)
    const teamMenuOpen = Boolean(teamAnchorEl)
    const {toggleDrawer} = useToggleDrawerContext()
    const {setSelectedGroupId} = useBudgetsContext()
    const {closeTeamBudget} = useBudgetsContext()
    const {user} = useUser()

    const handleTeamClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setTeamAnchorEl(event.currentTarget)
    }

    const handleDrawerView = (event: React.MouseEvent<HTMLLIElement>, drawerType: DrawerType) => {
        setSelectedGroupId(groupId)
        toggleDrawer(true, drawerType)(event)
        handleCloseMenu()
    }

    const handleCloseMenu = () => {
        setTeamAnchorEl(null)
    }

    const handleTeamRemoveLimit = () => {
        setShowRemoveTeamLimitDialog(true)
        handleCloseMenu()
    }

    const removeTeamLimitBudgetHandler = () => {
        setShowRemoveTeamLimitDialog(false)
        TrackActionEvent('Budget', user?.externalId ?? user?.id, {action: 'remove', type: 'team'})
        if (budget) closeTeamBudget(budget)
    }

    return <>
        <GridActionsCellItem
            label='Team limit actions'
            onClick={handleTeamClick}
            icon={<MoreVertIcon className='actionIcon'/>}
        />
        {budget ? <Menu anchorEl={teamAnchorEl} open={teamMenuOpen} onClose={handleCloseMenu}>
            <MenuItem className='spendingLimitMenuItem' disableRipple onClick={(event) => handleDrawerView(event, 'TEAM_BUDGET_DETAIL_DRAWER')}>
                <ListItemIcon><RemoveRedEyeOutlinedIcon fontSize='small' className='actionIcon'/></ListItemIcon>
                <Typography className='spendingLimitMenuItemText'>View details</Typography>
            </MenuItem>
            <Divider className='menuItemDivider'/>
            <MenuItem className='spendingLimitMenuItem' disableRipple onClick={(event) => handleDrawerView(event, 'EDIT_TEAM_BUDGET')}>
                <ListItemIcon><EditOutlinedIcon fontSize='small' className='actionIcon'/></ListItemIcon>
                <Typography className='spendingLimitMenuItemText'>Edit limit</Typography>
            </MenuItem>
            <Divider className='menuItemDivider'/>
            <MenuItem className='spendingLimitMenuItem' onClick={handleTeamRemoveLimit} disableRipple >
                <ListItemIcon><DeleteOutlineOutlinedIcon fontSize='small' className='actionIcon'/></ListItemIcon>
                <Typography className='spendingLimitMenuItemText'>Remove limit</Typography>
            </MenuItem>
        </Menu> : <Menu anchorEl={teamAnchorEl} open={teamMenuOpen} onClose={handleCloseMenu}>
            <MenuItem onClick={(event) => handleDrawerView(event, 'CREATE_TEAM_BUDGET')} disableRipple>
                <ListItemIcon>
                    <PaymentsOutlinedIcon fontSize='small' className='actionIcon'/>
                </ListItemIcon>
                Set team limit
            </MenuItem>
        </Menu>}
        <ConfirmationDialog handleClose={() => setShowRemoveTeamLimitDialog(false)} open={showRemoveTeamLimitDialog}
                            currentEvent='teamBudgetDeletion' handleDelete={removeTeamLimitBudgetHandler}/>
    </>
}

export const TeamLimit = ({budget}: TeamBudgetProps) => {
    return budget ?
        <Box className='teamLimit'>Limit: {formatAmount(budget.amount)}</Box> :
        <Box className='organizationLimit'>Limit: Organisation</Box>
}

export const TeamLimitReset = ({budget}: TeamBudgetProps) => {
    return budget ? <Box className='spendingResetContainer'>
        <Typography className='spendingResetLabel'>Spending reset:</Typography>
        <Typography className='spendingResetDate'>{formatDateToLocaleDate(getClosestBudgetPeriodEndDate(budget))}</Typography>
    </Box> : <></>
}