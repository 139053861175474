import {createTheme} from '@mui/material'

const narusFonts = 'Inter, Open Sans, Segoe UI, Roboto, sans-serif'

export const loadMoreButtonTheme = createTheme({
	palette: {
		primary: {
			main: '#825ECA',
			light: '#825ECA',
			dark: '#825ECA',
			contrastText: '#825ECA',
		},
		secondary: {
			main: '#825ECA'
		},
	},
	typography: {
		button: {
			textTransform: "none"
		}
	}
})

export const CUSTOM_THEME = createTheme({
	components: {
		MuiLink: {
			styleOverrides: {
				root: {
					fontFamily: narusFonts,
					textDecoration: 'none',
					fontSize: 14,
					fontWeight: 400,
					color: '#825ECA'
				}
			}
		},
		MuiButton : {
			styleOverrides: {
				root: {
					"&.Mui-disabled": {
						backgroundColor: '#EAEADF',
						color: '#FFFFFF'
					}
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					fontFamily: narusFonts,
					fontSize: 14,
					fontWeight: 400,
					color: '#010B11',
					padding: 2
				},
			}
		}
	},
	palette: {
		primary: {
			main: '#825ECA',
			light: '#825ECA',
			dark: '#825ECA',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#825ECA'
		},
	},
	typography: {
		allVariants: {
			fontFamily: narusFonts,
		},
		button: {
			textTransform: "none"
		},
		subtitle1: {
			color: '#44546F',
			fontSize: 12,
			fontWeight: 400,
		},
		body1: {
			fontWeight: 500,
		},
		body2: {
			color: '#151C33',
			fontSize: 12,
			fontWeight: 600
		},
		h3: {
			color: '#151C33',
			fontSize: 24,
			fontWeight: 700,
		},
		h4: {
			color: '#172B4D',
			fontSize: 20,
			fontWeight: 700,
			margin: '0 0 12px 0'
		}
	},
})
