import {FC, useCallback} from 'react'
import {Box, Chip, Tooltip, TooltipProps, Typography} from '@mui/material'
import {FileUpload} from '../../types/File'
import {useFilesContext} from '../../context/FilesContext'
import {FileUploadChipIcon} from './FileUploadChipIcon'
import {canRetryFileUpload, getHumanReadableFileSize} from '../../utils/fileUtils'
import {CustomEllipsedText} from '../common/customEllipsedText/CustomEllipsedText'
import DeleteIcon from '@mui/icons-material/Close'
import RetryIcon from '@mui/icons-material/Replay'
import './FileUploadChips.scss'

const tooltipClasses: TooltipProps['classes'] = {
    tooltip: 'fileUploadChip_ErrorTooltip'
}

type Props = {
    fileUploads: FileUpload[]
    align?: 'left' | 'right'
}

export const FileUploadChips: FC<Props> = ({
    fileUploads,
    align
}) => {
    const suffix = align === 'right' ? 'fileUploadChips--right' : 'fileUploadChips--left'
    return <Box className={`fileUploadChips ${suffix}`}>
        { fileUploads.map(fileUpload => <FileUploadChip key={fileUpload.metadata.id} fileUpload={fileUpload}/>) }
    </Box>
}

type FileUploadChipProps = {
    fileUpload: FileUpload
}

const FileUploadChip: FC<FileUploadChipProps> = ({
    fileUpload
}) => {
    const { removeFile, retryUpload } = useFilesContext()
    const { state, metadata, errorMessage, errorType} = fileUpload
    const label = state === 'loading' ? 'uploading...' : metadata.name
    const labelSize = getHumanReadableFileSize(fileUpload.file.size, true)
    const hasTitleTooltip = state === 'error' ? undefined : true
    const showLabelSize = state !== 'error' || errorType !== 'internal'
    const showViewError = state === 'error'
    const showRetryButton = state === 'error' && errorType === 'internal' 
    const errorTooltipTitle = <>
    <p>{metadata.name}</p>
    <p>{errorMessage}</p>
    </>

    const clickable = canRetryFileUpload(fileUpload)

    const handleDelete = useCallback(() => {
        removeFile(fileUpload)
    }, [fileUpload, removeFile])

    const handleClick = useCallback(() => {
        if (clickable) {
            retryUpload(fileUpload)
        }
    },[clickable, fileUpload, retryUpload])

    const component = <Chip
        className='fileUploadChip'
        deleteIcon={<DeleteIcon/>}
        icon={<FileUploadChipIcon fileUpload={fileUpload}/>}
        color={state === 'error' ? 'error' : state === 'completed' ? 'default' : 'info'}
        label={<Box className='fileUploadChip_LabelContainer'>
            <CustomEllipsedText className='fileUploadChip_LabelTitle' hasTooltip={hasTitleTooltip}>
                {label}
            </CustomEllipsedText>
            <Box className={`fileUploadChip_Subtitle${showRetryButton ? ' --spaced' : ''}`}>
                {showLabelSize ? <Typography className='fileUploadChip_LabelSubtitle'>{labelSize}</Typography> : <></>}
                {showViewError ? <Typography className='fileUploadChip_LabelError'>View error</Typography> : <></>}
                {showRetryButton
                    ? <Box className='fileUploadChip_RetryButton' onClick={handleClick}>
                        <RetryIcon fontSize='small'/>
                        <Typography>Retry</Typography>
                    </Box> : <></>}
            </Box>
            
        </Box>}        
        onDelete={handleDelete} 
        />

    return state === 'error'
            ? <Tooltip title={errorTooltipTitle} classes={tooltipClasses}>
                {component}
            </Tooltip>
            : component
}