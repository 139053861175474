import {Box, Dialog, DialogContent, DialogTitle, Link, Typography} from '@mui/material'
import './SaveSummarisedPromptNotification.scss'
import './SaveSummarisedPromptNotificationDialog.scss'
import {SAVE_PROMPT_DOCUMENTATION_URL} from '../../constants/Links'
import HelpIcon from '@mui/icons-material/Help'
import gifImage from '../../images/save-prompt-tutorial.gif'
import CloseIcon from '@mui/icons-material/Close'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'

interface SaveSummarisedPromptNotificationDialogProps {
    open: boolean
    onClose: () => void
}

const SaveSummarisedPromptNotificationDialog = ({open, onClose} : SaveSummarisedPromptNotificationDialogProps) => {
    const {user} = useUser()
    return (
        <Dialog open={open} className='SaveSummarisedPromptNotificationDialog' fullWidth={true}>
            <DialogTitle className='SaveSummarisedPromptNotificationDialog_Header'>
                <Box className='SaveSummarisedPromptNotificationDialog_HeaderTitle'>
                    <Typography>Save summarised prompts</Typography>
                    <Typography>This feature merges your input into a refined prompt.</Typography>
                </Box>
                <CloseIcon
                    onClick={onClose}
                    className='SaveSummarisedPromptNotificationDialog_HeaderIcon'
                />
            </DialogTitle>
            <DialogContent className='SaveSummarisedPromptNotificationDialog_Content'>
                <img
                    className='SaveSummarisedPromptNotificationDialog_ContentImage'
                    src={gifImage}
                    alt='how to save summarised prompts'
                    loading='eager'
                />
                <Box className='SaveSummarisedPromptNotificationDialog_ContentHelp'>
                    <HelpIcon/>
                    <Typography>
                        Need more help?
                        <Link
                            className='helpLink'
                            href={SAVE_PROMPT_DOCUMENTATION_URL}
                            onClick={() => TrackActionEvent('Summarised Prompt Notification', user?.externalId ?? user?.id, {action: 'click_docs'})}
                            target='_blank'>
                            View user documentation
                        </Link>
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default SaveSummarisedPromptNotificationDialog
