import {FC, useEffect, useRef, useState} from 'react'
import {Box, Button, Divider, IconButton, Tooltip, TooltipProps} from '@mui/material'
import {PromptChatDetail} from '../../types/PromptChatDetail'
import {useToggleDrawerContext} from '../../context/ToggleDrawerContext'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import SaveSummarizedPromptIcon from '@mui/icons-material/LibraryBooksOutlined'
import SaveChatIcon from '@mui/icons-material/SaveOutlined'
import './TaskBar.scss'
import {useUserInfoContext} from '../../context/UserInfoContext'
import SaveSummarisedPromptNotification from '../saveSummarisedPrompt/SaveSummarisedPromptNotification';

const tooltipClasses: TooltipProps['classes'] = {
    tooltip: 'TaskBar_Tooltip'
}

type Props = {
    promptChatDetails: PromptChatDetail
    isPromptOwnedByOther: boolean
    onOpenAdvancedSettings: () => void
    onSavePromptClicked: () => void
}

export const CustomTaskBar: FC<Props> = ({
    promptChatDetails,
    isPromptOwnedByOther, 
    onOpenAdvancedSettings,
    onSavePromptClicked
}) => {

    const {toggleDrawer} = useToggleDrawerContext()
    const {userInfo, updateNotificationCount} = useUserInfoContext()
    const saveButtonRef = useRef<HTMLButtonElement | null>(null)
    const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false)
    const [hasShownNotification, setHasShownNotification] = useState<boolean>(false)
    const saveButtonTooltipText = isPromptOwnedByOther ? 'You can\'t edit fields in a prompt that you are not the owner of' : 'Save summarized prompt'
    const {messages} = promptChatDetails
    const viewCount = userInfo?.saveNotificationViewCount ?? 0

    useEffect(() => {
        if (!hasShownNotification && viewCount < 2) {
            const userMessagesLength = messages.filter(message => message.sender === 'user').length
            if (userMessagesLength === 2) {
                setIsNotificationOpen(true)
                setHasShownNotification(true)
                updateNotificationCount(viewCount + 1)
            }
        }
    }, [messages, viewCount, updateNotificationCount, hasShownNotification, setHasShownNotification])

    return (
        <Box className='TaskBar_Container'>
            <Button className='TaskBar_Button'
                size='small'
                startIcon={<SaveChatIcon/>}
                disabled={!messages.length}
                onClick={toggleDrawer(true, 'SAVE_CHAT_HISTORY_DRAWER')}>Save chat</Button>
            <Divider orientation='vertical' flexItem/>
            <Tooltip title={saveButtonTooltipText} classes={tooltipClasses}>
                <span>
                    <IconButton className='TaskBar_IconButton'
                        size='small'
                        disabled={!promptChatDetails.messages.length || isPromptOwnedByOther}
                        ref={saveButtonRef}
                        onClick={onSavePromptClicked}
                    >
                        <SaveSummarizedPromptIcon fontSize='small'/>
                    </IconButton>
                </span>
            </Tooltip>
            <Divider orientation='vertical' flexItem/>
            <Tooltip title='Settings' classes={tooltipClasses}>
                <span>
                    <IconButton className='TaskBar_IconButton'
                        size='small'
                        disabled={!messages.length}
                        onClick={onOpenAdvancedSettings}>
                        <SettingsIcon fontSize='small'/>
                    </IconButton>
                </span>
            </Tooltip>
            <SaveSummarisedPromptNotification
                anchorEl={saveButtonRef.current}
                isOpen={isNotificationOpen}
                onClose={() => setIsNotificationOpen(false)}
            />
        </Box>
    )
}
