import { FileBlockingError } from "../types/File"

export const AUDIO_EXTENSIONS: readonly string[] = [
    'aac' , 'aiff', 'alac', 'amr', 'ape',
    'au'  , 'flac', 'm4a' , 'm4b', 'mp3',
    'ogg' , 'oga' , 'opus', 'wav', 'wma',
    'wv'  , 'ac3' , 'dts' , 'gsm', 'mid', 
    'midi', 'mod' , 'ra'  , 'rm' , 'vox',
    'aifc', 'caf' , 'dsd' , 'lpcm',      
] as const

export const IMAGE_EXTENSIONS: readonly string[] = [
    'jpg' , 'jpeg', 'png' , 'gif', 'bmp' ,
    'tiff', 'tif' , 'webp', 'svg', 'avif',
    'heic', 'jfif', 'ico' , 'psd', 'raw' ,
    'cr2' , 'nef' , 'orf' , 'arw', 'dng' ,
] as const

export const VIDEO_EXTENSIONS: readonly string[] = [
'mp4' , 'mkv' , 'avi', 'mov', 'wmv', 'flv' , 'mpeg',
'mp3' , 'aac' , 'm4a', 'wav', 'ogg', 'flac', 'mpe' ,
'webm', 'ogv' , 'avi', 'mov', 'wmv', 'flv' , 'mpg' ,
'h264', 'hevc', 'vp8', 'vp9', 'av1',
] as const

export const COMPRESSED_EXTENSIONS: readonly string[] = [
    'zip', 'rar', 'tar' , 'gz',
    '7z' , 'bz2', 'xz'  , 'tgz',
    'z'  , 'lz' , 'lzma', 'cab',
    'iso', 'arj', 'lzh',
] as const

export const CODE_EXTENSIONS: readonly string[] = [
    'js'  , 'ts', 'jsx', 'tsx', 'mjs', 'cjs',
    'html', 'htm', 'css', 'scss', 'sass', 'less',
    'json', 'xml', 'java', 'py', 'cpp', 'c',
    'h'   , 'hpp', 'cs', 'go', 'php', 'rb',
    'kt'  , 'rs', 'lua', 'sql', 'pl',
    'sh'  , 'ps1', 'vb', 'm', 'mm', 'md',
    'txt' , 'yml', 'yaml', 'toml', 'ini', 'properties',
    'gradle', 'pom', 'sln', 'gitignore', 
    'csproj', 'vbproj', 'swift', 'npmignore', 
]

export const FILE_BLOCKING_ERROR_MESSAGE_MAP: { [key in FileBlockingError]: string } = {
    size: 'File size exceeds 200MB limit.',
    type: 'File type not supported',
}

/** The maximum amount of times that a file upload check is executed per file before giving up */
export const MAX_CHECK_FILE_RETRIES = 200

export const UNSUPPORTED_FILE_EXTENSIONS: readonly string[] = [
    ...AUDIO_EXTENSIONS,
    ...IMAGE_EXTENSIONS,
    ...VIDEO_EXTENSIONS,
    ...COMPRESSED_EXTENSIONS,
] as const