import {FC, forwardRef, SyntheticEvent} from 'react'
import MuiAlert, {AlertProps} from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import {useFeedbackContext} from '../../context/FeedbackContext'
import {AlertTitle} from '@mui/material'

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant='standard' {...props} />
})

type Props = {
    left?: number | string | undefined
}

export const FeedbackMessage: FC<Props> = ({
    left
}) => {
    const {displayFeedback, feedbackTitle, feedbackMessage, feedbackMessageType, setDisplayFeedback} = useFeedbackContext()

    const handleClose = (_: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return
        setDisplayFeedback(false)
    }

    return <>
        <Snackbar
            open={displayFeedback}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert onClose={handleClose} className='Alert' severity={feedbackMessageType} style={{ transform: left ? `translateX(${left})` : 'initial' }}>
                <AlertTitle>{feedbackTitle}</AlertTitle>
                {feedbackMessage}
            </Alert>
        </Snackbar>
    </>
}
