import {Box, Button, Typography} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useAccountCreationContext } from '../../context/AccountCreationContext'
import { FC } from 'react'
import { StepIndex } from '../../types/AccountCreation'
import './AccountCreationError.scss'

type AccountCreationErrorProps = {
	index: StepIndex
}

export const AccountCreationError: FC<AccountCreationErrorProps> = ({
	                                                                    index
                                                                    }) => {
	const {resetStepState} = useAccountCreationContext()

	const handleButtonClicked = () => {
		resetStepState(index)
	}

	return <Box className='AccountCreationError'>
		<Box className='AccountCreationError_Wrapper'>
	        <ErrorOutlineIcon className='AccountCreationError_Icon'/>
	        <Box className='AccountCreationError_Content'>
	            <Typography variant='h3' className='AccountCreationError_Title'>Sorry, we were unable to synchronize the data.</Typography>
	            <Typography variant='h5' className='AccountCreationError_Subtitle'>We had a problem connecting to the database, please reload the page and enter the data again.</Typography>
	            <Button variant='contained' onClick={handleButtonClicked} className='AccountCreationError_ReloadButton'>Reload</Button>
	        </Box>
		</Box>
    </Box>
}