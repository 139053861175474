export const SHARE_FEEDBACK_URL = 'https://adaptavistlabs.atlassian.net/wiki/spaces/KKM/pages/3580821929/Narus+-+Internal+user+feedback'
export const USER_DOCUMENTATION_URL = 'https://www.narus.ai/resources/documentation'
export const END_USER_DOCUMENTATION_URL = 'https://www.narus.ai/help-center-category/use-narus'
export const USER_SUPPORT_EMAIL = 'support@narus.ai'
export const AWS_MARKETPLACE_URL = 'https://aws.amazon.com/marketplace/pp/prodview-3gdsp3a5zsois'
export const SALES_TEAM_EMAIL = 'hello@narus.ai'
export const LLAMA3_2_LICENSE_URL = 'https://www.llama.com/llama3_2/license/'
export const APP_URL = process.env.REACT_APP_FRONTEND_URL ?? ''
export const ADD_USER_DOMAINS_URL = 'https://www.narus.ai/documentation/add-users-to-your-workspace'
export const SAVE_PROMPT_DOCUMENTATION_URL = 'https://www.narus.ai/documentation/save-a-prompt'
