import {BudgetPeriodicityCustomType} from '../types/Budget'

export const BUDGET_CHART_DISPLAYED_MODELS = 3
export const BUDGET_PERIODICITY_MULTIPLIERS: {
    Monthly: number
    Quarterly: number
    Custom: { [Key in BudgetPeriodicityCustomType]: number }
} = {
    Monthly: 30,
    Quarterly: 90,
    Custom: {
        Days: 1,
        Weeks: 7,
        Months: 30
    }
} as const
export const BUDGET_PERIODICITY_CUSTOM_OPTIONS: BudgetPeriodicityCustomType[] = ['Days', 'Weeks', 'Months']