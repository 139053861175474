import {createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {Budget, ClosedBudget, GroupWithBudget} from '../types/Budget'
import {closeBudget, createBudget, getClosedBudgets, getCurrentGroupBudgets, getOverallBudget, updateBudget} from '../service/persistenceService'
import {useUserContext} from './UserContext'
import {useFeedbackContext} from './FeedbackContext'
import {useStripeSubscriptionsContext} from './StripeSubscriptionsContext'
import {useGroupsContext} from './GroupsContext'

export interface BudgetsContextValue {
	loading: boolean
	loadingClosedBudgets: boolean
	loadingGroupBudgets: boolean
	isOverallBudgetFinished: boolean
	selectedGroupId?: string
	setSelectedGroupId: Dispatch<SetStateAction<string | undefined>>
	overallBudget: Budget | undefined
	groupBudgets: Budget[]
	groupsWithBudgets: GroupWithBudget[]
	closedBudgets: ClosedBudget[]
	saveBudget: (budget: Partial<Budget>) => Promise<Budget | undefined>,
	updateBudgetAmount: (budget: Budget, amount: number) => Promise<Budget | undefined>
	closeCurrentBudget : () => Promise<Budget | undefined>
	closeTeamBudget: (teamBudget: Budget) => Promise<Budget | undefined>
}

const DEFAULT_BUDGETS_CONTEXT: BudgetsContextValue = {
	loading: false,
	loadingClosedBudgets: false,
	loadingGroupBudgets: false,
	isOverallBudgetFinished: false,
	selectedGroupId: undefined,
	setSelectedGroupId: () => {},
	overallBudget: undefined,
	groupBudgets: [],
	groupsWithBudgets: [],
	closedBudgets: [],
	saveBudget: () => Promise.resolve(undefined),
	updateBudgetAmount: () => Promise.resolve(undefined),
	closeCurrentBudget: () => Promise.resolve(undefined),
	closeTeamBudget: () => Promise.resolve(undefined)
}

export const BudgetsContext = createContext<BudgetsContextValue>(DEFAULT_BUDGETS_CONTEXT)

export const useBudgetsContext = () => useContext(BudgetsContext)

export const BudgetsContextProvider: FC<PropsWithChildren> = ({children}) => {

	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()
	const {isCurrentSubscriptionGrowth} = useStripeSubscriptionsContext()
	const {groups} = useGroupsContext()

	const [overallBudget, setOverallBudget] = useState<Budget>()
	const [groupBudgets, setGroupBudgets] = useState<Budget[]>([])
	const [groupsWithBudgets, setGroupsWithBudgets] = useState<GroupWithBudget[]>([])
	const [closedBudgets, setClosedBudgets] = useState<ClosedBudget[]>([])
	const [selectedGroupId, setSelectedGroupId] = useState<string>()
	const [loading, setLoading] = useState(false)
	const [loadingClosedBudgets, setLoadingClosedBudgets] = useState(false)
	const [loadingGroupBudgets, setLoadingGroupBudgets] = useState(false)
	const [isOverallBudgetFinished, setIsOverallBudgetFinished] = useState(false)

	const fetchClosedBudgets = useCallback(() => {
		setLoadingClosedBudgets(true)
		getClosedBudgets(token)
			.then(budgets => {
				setClosedBudgets(budgets)
			})
			.finally(() => setLoadingClosedBudgets(false))
	}, [token])

	const saveBudget = useCallback((budget: Partial<Budget>): Promise<Budget | undefined> => {
		return createBudget(token, budget).then(budget => {
			if (budget.type === 'Organization') setOverallBudget(budget)
			else {
				setGroupBudgets(previousGroupBudgets => [...previousGroupBudgets, budget])
				setGroupsWithBudgets(previousGroupsWithBudgets => {
					const group = previousGroupsWithBudgets.find(group => group.hashKey === budget.groupId)
					if (group) group.budget = budget
					return previousGroupsWithBudgets
				})
			}
			showFeedback('Spending limit created', '', 'success')
			return budget
		})
			.catch(() => {
				showFeedback('Error', 'Something went wrong to create a spending limit. Try again reloading the page', 'error')
				return undefined
			})
	}, [token, showFeedback])

	const updateBudgetAmount = useCallback(({hashKey}: Budget, amount: number): Promise<Budget | undefined> => {
		return updateBudget(token, hashKey, amount).then(budget => {
			if (budget.type === 'Organization') setOverallBudget(budget)
			else {
				setGroupBudgets(previousGroupBudgets => [...previousGroupBudgets, budget])
				setGroupsWithBudgets(previousGroupsWithBudgets => {
					const group = previousGroupsWithBudgets.find(group => group.hashKey === budget.groupId)
					if (group?.budget) group.budget.amount = amount
					return previousGroupsWithBudgets
				})
			}
			showFeedback('Spending limit updated', '', 'success')
			return budget
		})
			.catch(() => {
				showFeedback('Error', 'Something went wrong to update the spending limit. Try again reloading the page', 'error')
				return undefined
			})
	}, [token, showFeedback])

	const closeCurrentBudget = useCallback((): Promise<Budget | undefined> => {
		if (!overallBudget) return Promise.resolve(undefined)
		return closeBudget(token, overallBudget.hashKey).then(budget => {
			setOverallBudget(undefined)
			showFeedback('Spending limit closed', '', 'success')
			fetchClosedBudgets()
			return budget
		})
			.catch(() => {
				showFeedback('Error', 'Something went wrong to close the current spending limit. Try again reloading the page', 'error')
				return undefined
			})
	}, [overallBudget, token, showFeedback, fetchClosedBudgets])

	const closeTeamBudget = useCallback((teamBudget: Budget): Promise<Budget | undefined> => {
		if (!teamBudget) return Promise.resolve(undefined)
		return closeBudget(token, teamBudget.hashKey)
			.then(closedBudget => {
				setGroupBudgets(prevBudgets => prevBudgets.filter(prevBudget => prevBudget.hashKey !== teamBudget.hashKey))
				setGroupsWithBudgets(prevGroups =>
					prevGroups.map(group =>
						group.hashKey === teamBudget.groupId ? {...group, budget: undefined} : group
					)
				)
				showFeedback('Team spending limit closed', '', 'success')
				fetchClosedBudgets()
				return closedBudget
			})
			.catch(() => {
				showFeedback('Error', 'Something went wrong closing team spending limit. Try again reloading the page', 'error')
				return undefined
			})
	}, [token, showFeedback, fetchClosedBudgets])

	useEffect(() => {
		if (!isCurrentSubscriptionGrowth) {
			setLoading(true)
			getOverallBudget(token)
				.then(overallBudget => {
					setOverallBudget(overallBudget)
					setIsOverallBudgetFinished(overallBudget ? overallBudget?.spent > overallBudget?.amount : false)
				})
				.finally(() => setLoading(false))
			fetchClosedBudgets()
		}
	}, [isCurrentSubscriptionGrowth, token, showFeedback, fetchClosedBudgets])

	useEffect(() => {
		if (!isCurrentSubscriptionGrowth) {
			setLoadingGroupBudgets(true)
			getCurrentGroupBudgets(token).then(groupBudgets => {
				setGroupBudgets(groupBudgets)
				setGroupsWithBudgets(groups.map(group => ({...group, budget: groupBudgets.find(groupBudget => groupBudget.groupId === group.hashKey)})))
			}).finally(() => setLoadingGroupBudgets(false))
		}
	}, [isCurrentSubscriptionGrowth, token, groups])

	const value: BudgetsContextValue = useMemo(() => ({
			loading, loadingClosedBudgets, isOverallBudgetFinished, overallBudget, groupBudgets: groupBudgets, closedBudgets,
			saveBudget, updateBudgetAmount, closeCurrentBudget, loadingGroupBudgets, groupsWithBudgets, selectedGroupId, setSelectedGroupId, closeTeamBudget}),
		[loading, loadingClosedBudgets, isOverallBudgetFinished, overallBudget, groupBudgets, closedBudgets,
			saveBudget, updateBudgetAmount, closeCurrentBudget, loadingGroupBudgets, groupsWithBudgets, selectedGroupId, setSelectedGroupId, closeTeamBudget])

	return <BudgetsContext.Provider value={value}>
		{children}
	</BudgetsContext.Provider>
}