import {Box, Button, Divider, Drawer, Grid, IconButton, Menu, MenuItem, Tab, Tabs, Tooltip, Typography} from '@mui/material'
import {useMemo, useState} from 'react'
import './BudgetControl.scss'
import {useGroupsContext} from '../../../../context/GroupsContext'
import {EmptyGroups} from '../../../emptySection/EmptyGroups'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import {GroupCreation} from '../groups/GroupCreation'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {useBudgetsContext} from '../../../../context/BudgetContext'
import {formatDateStringToLocaleDate} from '../../../../helpers/DateHelpers'
import ConfirmationDialog from '../../../promptForm/confirmationDialog/ConfirmationDialog'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {formatAmount} from '../../../../utils/currencyUtils'
import {CustomTabPanel} from '../../../homeTabs/HomeTabs'
import {DataGridTable} from '../../DataGridTable'
import {GridColDef} from '@mui/x-data-grid'
import {EditBudget} from './EditBudget'
import {BudgetSummary} from './BudgetSummary'
import Spinner from '../../../spinner/Spinner'
import {TeamBudgetActions, TeamLimit, TeamLimitReset} from './TeamBudget'
import {getClosestBudgetPeriodEndDate} from '../../../../utils/budgetUtils'
import {GroupWithBudget} from '../../../../types/Budget'
import {BudgetForm} from './BudgetForm'
import { BudgetHistory } from './BudgetHistory'

export const BudgetControl = () => {

	const {user} = useUser()
	const {loading} = useGroupsContext()
    const {
        toggleDrawer,
        displayDrawerCreateGroup,
        displayDrawerEditOverallBudget,
        displayDrawerCreateOverallBudget,
        displayDrawerDetailOverallBudget,
        displayDrawerCreateTeamBudget,
        displayDrawerDetailTeamBudget,
      	displayDrawerEditTeamBudget
    } = useToggleDrawerContext()
	const {loading: budgetLoading, loadingGroupBudgets, overallBudget, groupsWithBudgets, closeCurrentBudget, selectedGroupId} = useBudgetsContext()
	const [selectedTab, setSelectedTab] = useState<number>(0)
	const [showBudgetDeletionDialog, setShowBudgetDeletionDialog] = useState<boolean>(false)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const groupBudget = groupsWithBudgets.find(group => group.hashKey === selectedGroupId)?.budget

	const handleTabChange = (_: React.SyntheticEvent, tabIndex: number) => {
		const sectionNames = ['Settings', 'Limits History']
		setSelectedTab(tabIndex)
		TrackActionEvent('Spending Limits tab', user?.externalId ?? user?.id, {section: sectionNames[tabIndex]})
	}

	const deleteOverallBudgetHandler = () => {
		TrackActionEvent('Budget', user?.externalId ?? user?.id, {action: 'remove', type: 'overall'})
		setShowBudgetDeletionDialog(false)
		if (overallBudget) closeCurrentBudget()
		handleClose()
	}

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleEditOverallBudgetLimit = (event: React.MouseEvent<HTMLLIElement>) => {
		handleClose()
		if (overallBudget) toggleDrawer(true, 'EDIT_OVERALL_BUDGET')(event)
	}

	const handleShowBudgetDeletionDialog = () => {
		setShowBudgetDeletionDialog(true)
		handleClose()
	}

	const handleShowBudgetSummary = (event: React.MouseEvent) => {
		toggleDrawer(true, 'OVERALL_BUDGET_DETAIL_DRAWER')(event)
		handleClose()
	}


	const columns: GridColDef[] = useMemo(() => [
		{field: 'name', headerName: 'Team name', flex: 1, cellClassName: () => 'teamCell teamName'},
		{
			field: 'spendingReset', headerName: 'Spending reset', minWidth: 200, cellClassName: () => 'teamCell',
			valueGetter: (_, row: GroupWithBudget) => row.budget ? getClosestBudgetPeriodEndDate(row.budget).getTime() : Infinity,
			sortComparator: (groupSpendingReset1, groupSpendingReset2) => groupSpendingReset2 - groupSpendingReset1,
			renderCell: (params) => <TeamLimitReset groupId={params.row.hashKey} budget={params.row.budget}/>
		},
		{field: 'limit', headerName: 'Limit', minWidth: 156, cellClassName: () => 'teamCell',
			renderCell: (params) => <TeamLimit groupId={params.row.hashKey} budget={params.row.budget}/>},
		{field: 'actions', type: 'actions', headerName: 'Actions', getActions: (params) => [<TeamBudgetActions groupId={params.row.hashKey} budget={params.row.budget}/>]}
	], [])

	return (
		<Box className='budgetControlContainer'>
			<Typography className='title'>Spending Limits</Typography>
			<Typography className='subtitle'>Set spending limits for your entire organisation and individual teams.</Typography>
			<Box className='tabsContainer'>
				<Tabs value={selectedTab} onChange={handleTabChange}>
					<Tab label='Settings'/>
					<Tab label='Limits History'/>
				</Tabs>
			</Box>
			<CustomTabPanel value={selectedTab} index={0}>
				<Grid item xs={12}>
					<Box className='organizationBudgetContainer'>
						<Box className='budgetLimitContainer'>
							<Typography className='title'>Organisation limit</Typography>
							<Typography className='subtitle'>
								{overallBudget ? formatAmount(overallBudget.amount) : <>
									Unlimited <Tooltip classes={{tooltip: 'unlimitedTooltip'}}
									                   title='If the overall budget for the workspace is not defined, it will be unlimited for employees with access to AI in Narus'
									                   placement='right'
									                   slotProps={{
										                   popper: {
											                   modifiers: [{name: 'offset', options: {offset: [10, 0]}}]
										                   }
									                   }}>
									<InfoOutlinedIcon/>
								</Tooltip></>}
							</Typography>
							{overallBudget ?
								<Typography className='renewedBudget'>Spending reset: {formatDateStringToLocaleDate(overallBudget.periodStartDate)} </Typography> : <></>}
						</Box>
						<Box className='buttonsRow'>
							{overallBudget && (
								<Box>
									<IconButton className='spendingLimitMoreIcon' onClick={handleClick}>
										<MoreVertIcon/>
									</IconButton>
									<Menu className='spendingLimitMoreMenu' anchorEl={anchorEl} open={open} onClose={handleClose}
									      anchorOrigin={{vertical: 'bottom', horizontal: 57}}
									      transformOrigin={{vertical: 'top', horizontal: 'right'}}
									>
										<MenuItem className='spendingLimitMenuItem' disableRipple onClick={handleShowBudgetSummary}>
											<RemoveRedEyeOutlinedIcon className='spendingLimitMenuItemIcon'/>
											<Typography className='spendingLimitMenuItemText'>View details</Typography>
										</MenuItem>
										<Divider className='menuItemDivider'/>
										<MenuItem className='spendingLimitMenuItem' disableRipple onClick={handleEditOverallBudgetLimit}>
											<EditOutlinedIcon className='spendingLimitMenuItemIcon'/>
											<Typography className='spendingLimitMenuItemText'>Edit limit</Typography>
										</MenuItem>
										<Divider className='menuItemDivider'/>
										<MenuItem className='spendingLimitMenuItem' disableRipple onClick={handleShowBudgetDeletionDialog}>
											<DeleteOutlineOutlinedIcon className='spendingLimitMenuItemIcon'/>
											<Typography className='spendingLimitMenuItemText'>Remove limit</Typography>
										</MenuItem>
									</Menu>
								</Box>)}
							{!overallBudget && (
								<Button className='setOrgLimitButton' variant='contained' onClick={toggleDrawer(true, 'CREATE_OVERALL_BUDGET')}>Set organisation limit</Button>)}
						</Box>
					</Box>
				</Grid>

				{!groupsWithBudgets.length && !loading && !budgetLoading && !loadingGroupBudgets ? <Grid item xs={12} className='budgetControlNoTeams'>
					<EmptyGroups>
						<Box className='noTeamsButtonContainer'>
							<Button variant='outlined' onClick={toggleDrawer(true, 'CREATE_GROUP_DRAWER')}>Create a new team</Button>
						</Box>
					</EmptyGroups>
				</Grid> : <Grid item xs={12} className='budgetControlTeamsContainer'>
					<DataGridTable columns={columns} rows={groupsWithBudgets} analyticEventName='Group limit' loading={loading || budgetLoading || loadingGroupBudgets}
					               currentTable='GROUP_LIMITS' hideHeaders={true} hideToolbar={true} disableRowSelectionOnClick={true} rowHeight='auto'
					               fieldToSort='spendingReset'/>
				</Grid>}

				<Drawer anchor='right' open={displayDrawerCreateGroup} onClose={toggleDrawer(false, 'CREATE_GROUP_DRAWER')}>
					<GroupCreation/>
				</Drawer>
				<Drawer anchor='right' open={displayDrawerDetailOverallBudget} onClose={toggleDrawer(false, 'OVERALL_BUDGET_DETAIL_DRAWER')}>
					{!budgetLoading && overallBudget ? <BudgetSummary budget={overallBudget} type='Organization' onClose={toggleDrawer(false, 'OVERALL_BUDGET_DETAIL_DRAWER')}/> : <Spinner/>}
				</Drawer>
				<Drawer anchor='right' open={displayDrawerDetailTeamBudget} onClose={toggleDrawer(false, 'TEAM_BUDGET_DETAIL_DRAWER')}>
					{!loadingGroupBudgets && groupBudget ? <BudgetSummary budget={groupBudget} type='Group' onClose={toggleDrawer(false, 'TEAM_BUDGET_DETAIL_DRAWER')}/> : <Spinner/>}
				</Drawer>
				<Drawer anchor='right' open={displayDrawerEditOverallBudget} onClose={toggleDrawer(false, 'EDIT_OVERALL_BUDGET')}>
					<EditBudget type='Organization'/>
				</Drawer>
				<Drawer anchor='right' open={displayDrawerEditTeamBudget} onClose={toggleDrawer(false, 'EDIT_TEAM_BUDGET')}>
					<EditBudget type='Group'/>
				</Drawer>
				<Drawer anchor='right' open={displayDrawerCreateOverallBudget} onClose={toggleDrawer(false, 'CREATE_OVERALL_BUDGET')}>
					<BudgetForm type='Organization'/>
				</Drawer>
				<Drawer anchor='right' open={displayDrawerCreateTeamBudget} onClose={toggleDrawer(false, 'CREATE_TEAM_BUDGET')}>
					<BudgetForm type='Group'/>
				</Drawer>
				<ConfirmationDialog handleClose={() => setShowBudgetDeletionDialog(false)} open={showBudgetDeletionDialog}
				                    currentEvent='overallBudgetDeletion' handleDelete={deleteOverallBudgetHandler}/>
			</CustomTabPanel>
			<CustomTabPanel value={selectedTab} index={1}>
				<BudgetHistory/>
			</CustomTabPanel>
		</Box>
	)
}

