import {FC} from 'react'
import {
    Typography,
    Button,
    Box
} from '@mui/material'
import PaymentOutlinedIcon from '@mui/icons-material/PaymentsOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CloseIcon from '@mui/icons-material/Close'
import './BudgetSummary.scss'
import {formatDateStringToLocaleDate} from '../../../../helpers/DateHelpers'
import {Budget} from '../../../../types/Budget'

interface Props {
    type: 'Organization' | 'Group'
    onClose: (event: React.MouseEvent) => void
    budget: Budget
}

export const BudgetSummary: FC<Props> = ({type, onClose, budget}) => {

    const {amount, creationDate, creatorEmail, creatorUserId, endDate, periodicity, startDate} = budget

    return <Box className='BudgetSummary_Container'>
        <Box className='BudgetSummary_TitleContainer'>
            <Box className='BudgetSummary_Title'>
                <PaymentOutlinedIcon/>
                <Typography>
                    {type === 'Organization' ? 'Organisation' : 'Team'} limit summary
                </Typography>
            </Box>
            <CloseIcon onClick={onClose} cursor='pointer'
                       className='BudgetSummary_CloseIcon'/>
        </Box>
        <Box className='BudgetSummary_InfoContainer'>
            <InfoOutlinedIcon color="info" fontSize='small'/>
            <Typography>
                Costs are estimated. Actual bill may vary.
            </Typography>
        </Box>
        <Box className='BudgetSummary_BudgetInfoContainer'>
            <BudgetInfoItem label='Limit amount:' value={`$${amount}`}/>
            <BudgetInfoItem label='Created by:' value={creatorEmail ?? creatorUserId}/>
            <BudgetInfoItem label='Date of creation:' value={formatDateStringToLocaleDate(creationDate)}/>
            <BudgetInfoItem label='Start date:' value={formatDateStringToLocaleDate(startDate)}/>
            <BudgetInfoItem label='Spending limit cycle:' value={periodicity}/>
            <BudgetInfoItem label='End date:' value={endDate === new Date('9999-12-31').getTime() ? 'Never' : formatDateStringToLocaleDate(endDate)}/>
        </Box>
        <Box className='BudgetSummary_CloseButtonContainer'>
            <Button
                variant='outlined'
                onClick={onClose}
            >
                Close
            </Button>
        </Box>
    </Box>
}

interface BudgetInfoItemProps {
    label: string
    value: string
}

const BudgetInfoItem: FC<BudgetInfoItemProps> = ({label, value}: BudgetInfoItemProps) =>
    <Box className='BudgetSummary_InfoItemContainer'>
        <Typography className='BudgetSummary_InfoItemLabel'>{label}</Typography>
        <Typography className='BudgetSummary_InfoItemValue'>{value}</Typography>
    </Box>