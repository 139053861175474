import {useUserContext} from '../context/UserContext'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {
    createGroup,
    getGroupMembersByGroupId,
    getGroups,
    removeGroup,
    updateGroup,
    updateGroupMembers
} from '../service/persistenceService'
import {GroupsContextValue} from '../context/GroupsContext'
import {useFeedbackContext} from '../context/FeedbackContext'
import {Group} from '../types/Group'
import {GroupMember, GroupMemberEntry} from '../types/GroupMember'
import {TrackActionEvent} from '../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {AIModelID} from '../types/AiModel'
import {addGroupMembers, areGroupMemberEntriesChanged, areGroupMemberEntriesMatch, areGroupsChanged, areGroupsMatch, getSortedGroupNames} from '../utils/groupUtils'
import {update, remove, add, updateWithComposition, hasProp, putArray, put} from '../utils/genericUtils'
import {useStripeSubscriptionsContext} from '../context/StripeSubscriptionsContext'

const defaultGroupMembers: GroupMember[] = []

export const useGroups = (): GroupsContextValue => {

    const {token} = useUserContext()
    const {showFeedback} = useFeedbackContext()
    const {user} = useUser()
    const { isCurrentSubscriptionGrowth } = useStripeSubscriptionsContext()

    const [groups, setGroups] = useState<Group[]>([])
    const [group, setGroup] = useState<Group>()
    const [loading, setLoading] = useState<boolean>(true)
    const [editingGroupMembers, setEditingGroupMembers] = useState<boolean>(false)
    const [groupMembers, setGroupMembers] = useState<GroupMember[]>(defaultGroupMembers)
    const [allGroupsMembers, setAllGroupsMembers] = useState<GroupMemberEntry[]>([])
    const [selectedGroup, setSelectedGroup] = useState<Group>()

    const retrieveGroups = useCallback((): Promise<Group[]> => {
        setLoading(true)

        return getGroups(token)
            .then(groups => {
                const sortedGroups = getSortedGroupNames(groups)
                setGroups(sortedGroups)
                return sortedGroups
            })
            .catch(() => {
                showFeedback('Error', 'Something went wrong to load groups. Try again reloading the page', 'error')
                setGroups([])
                return []
            })
            .finally(() => {
                setLoading(false)
            })
    }, [token, showFeedback])

    const retrieveGroupMembers = useCallback(async (groupId: string): Promise<GroupMember[]> => {
        setLoading(true)

        try {
            const groupMembers = await getGroupMembersByGroupId(token, groupId)
            setAllGroupsMembers(put({groupId, groupMembers}, areGroupMemberEntriesMatch, areGroupMemberEntriesChanged))
            return groupMembers
        } catch (error) {
            showFeedback('Error', 'Something went wrong to load the group members. Try again reloading the page', 'error')
            return []
        } finally {
            setLoading(false)
        }
    }, [token, showFeedback])

    const fetchAllGroupsMembers = useCallback(async () => {
        const groups = await retrieveGroups()
        if (groups) {
            const groupMembersPromises = groups.map(group => retrieveGroupMembers(group.hashKey).then(groupMembers => ({groupId: group.hashKey, groupMembers: groupMembers ?? []})))
            const resolvedGroupMembers = await Promise.all(groupMembersPromises)
            setAllGroupsMembers(putArray(resolvedGroupMembers, areGroupMemberEntriesMatch, areGroupMemberEntriesChanged))
        }
    }, [retrieveGroupMembers, retrieveGroups])

    const saveGroup = useCallback(async (groupName: string, usersToAddToGroup: string[]): Promise<{
        status: number,
        groupId: string
    }> => {
        const [status, group] = await createGroup(token, groupName, usersToAddToGroup)
            .catch(() => {
                return [undefined, undefined]
            })
        if(group && status) {
            setGroups(add(group, areGroupsMatch))
            setSelectedGroup(group)
            return {status, groupId: group.hashKey}
        }
        return {status: status ?? 500, groupId: group?.hashKey ?? ''}
    }, [token])

    const deleteGroup = useCallback((groupId: string) => {
        return removeGroup(token, groupId).then(() => {
            setGroups(remove(hasProp('hashKey', groupId)))
            setAllGroupsMembers(remove(hasProp('groupId', groupId)))
            TrackActionEvent('Groups', user?.externalId ?? user?.id, {
                action: 'delete',
                group_id: groupId
            })
            showFeedback('Success', 'The group was deleted!', 'success')
        }).catch(() => {
            showFeedback('Error', 'Something went wrong to remove the group.', 'error')
        })
    }, [token, showFeedback, user?.id, user?.externalId])

    const editGroup = useCallback((groupId: string, updatedGroupName: string, disabledAIModels: AIModelID[] = []) =>
        updateGroup(token, groupId, updatedGroupName, disabledAIModels)
            .then(updatedGroup => {
                setGroups(update(updatedGroup, areGroupsChanged))
                setSelectedGroup(updatedGroup)
                showFeedback('Success', 'The group was updated!', 'success')
                TrackActionEvent('Groups', user?.externalId ?? user?.id, {
                    action: 'edit',
                    group_id: groupId
                })
            })
            .catch(error => {
                showFeedback('Error', error.cause === 403 ?
                    'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
                    'Something went wrong to update the group. Try again reloading the page', 'error')
                return error.message
            })
    , [token, showFeedback, user?.id, user?.externalId])

    const editGroupMembers = useCallback((groupId: string, userIds: string[]) => {
        setEditingGroupMembers(true)
        return updateGroupMembers(token, groupId, userIds)
            .then(newGroupMembers => {
                setAllGroupsMembers(updateWithComposition(addGroupMembers({ groupId, groupMembers: newGroupMembers}), areGroupMemberEntriesChanged))
                showFeedback('Success', 'The group was updated!', 'success')
                TrackActionEvent('Groups', user?.externalId ?? user?.id, {
                    action: 'edit',
                    group_id: groupId
                })
            })
            .catch(error => {
                showFeedback('Error', error.cause === 403 ?
                    'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
                    'Something went wrong when adding group members. Try again reloading the page', 'error')
                return error.message
            })
            .finally(() => {
                setEditingGroupMembers(false)
            })
        }
    , [user?.id, user?.externalId, token, showFeedback])

    useEffect(() => {
        setSelectedGroup(prevSelectedGroup => {
            if (groups.length) {
                return groups.some(hasProp('hashKey', prevSelectedGroup?.hashKey))
                    ? prevSelectedGroup
                    : getSortedGroupNames(groups)[0]
            } else {
                return undefined
            }
        })
    }, [groups])

    useEffect(() => {
        if (selectedGroup) {
            setGroup(selectedGroup)
            retrieveGroupMembers(selectedGroup.hashKey)
        }
    }, [selectedGroup, retrieveGroupMembers])

    useEffect(() => {
        setGroupMembers(allGroupsMembers.find(hasProp('groupId', selectedGroup?.hashKey))?.groupMembers ?? defaultGroupMembers)
    }, [selectedGroup, allGroupsMembers])

     useEffect(() => {
        if(!isCurrentSubscriptionGrowth) fetchAllGroupsMembers()
    }, [isCurrentSubscriptionGrowth, fetchAllGroupsMembers])

    return useMemo(() => ({
        saveGroup,
        groups,
        loading,
        retrieveGroupMembers,
        groupMembers,
        allGroupsMembers,
        group,
        setGroup,
        deleteGroup,
        selectedGroup,
        setSelectedGroup,
        editGroup,
        editGroupMembers,
        editingGroupMembers,
    }), [saveGroup, groups, loading, retrieveGroupMembers, groupMembers, allGroupsMembers, group, deleteGroup, selectedGroup, editGroup, editGroupMembers, editingGroupMembers])
}
