import {internalSegmentId, externalSegmentId} from '../constants'
import type {UserResource} from '@clerk/types'
import { NARUS_ANALYTICS_APP_NAME } from '../constants/StripeConstants'
const {version} = require('../../package.json')

declare global {
    interface Window {
        analytics: any
        unloadAnalytics: any
        loadAnalytics: any
        Sprig: any
    }
}

interface UserAnalyticData {
    userId: string
    organizationId: string
    currentLanguage: string
    email: string
    fullName: string
    createdAt: Date | string
    lastSignInAt: Date | string
}

const getAnalytics = () => window.analytics
const getSprig = () => window.Sprig

export const LoadAnalytics = (external?: boolean) => {
    window.loadAnalytics?.(external ? externalSegmentId : internalSegmentId)
}
export const UnloadAnalytics = () => {
    window.unloadAnalytics?.()
}

export const PageView = (category: string) => {
    getAnalytics()?.page(category, undefined, {
        app_name: NARUS_ANALYTICS_APP_NAME
    })
}

export const AnalyticsIdentify = (userData?: UserResource | null, organizationId?: string) => {
    if (!userData) return
    getAnalytics()?.identify(userData.externalId ?? userData.id, buildUserData(userData, organizationId))
    getSprig()?.('setUserId', userData.externalId ?? userData.id)
    getSprig()?.('setAttributes', buildUserData(userData, organizationId))
}

export const GroupEvent = (userId?: string, traits: any = {}) => {
    getAnalytics()?.group(userId, traits)
}

export const TrackActionEvent = async (eventName: string, userId?: string, extraInfo?: any) => {
    getAnalytics()?.track(eventName, {
        userId,
        version: version,
        app_name: NARUS_ANALYTICS_APP_NAME,
        eventName,
        ...extraInfo
    })
    getSprig()?.('identifyAndTrack', {
        eventName: eventName,
        userId,
        properties: {
            ...extraInfo,
            version,
            app_name: NARUS_ANALYTICS_APP_NAME
        }
    })
}

export const TrackEvent = (props) => {
    getAnalytics()?.track(props['name'], props['data'])
}

const buildUserData = (user: UserResource, organizationId?: string): UserAnalyticData => ({
    userId: user.externalId ?? user.id ?? '',
    organizationId: organizationId ?? '',
    currentLanguage: navigator.language,
    fullName: user.fullName || '',
    email: user.emailAddresses?.[0]?.emailAddress || '',
    createdAt: user.createdAt || '',
    lastSignInAt: user.lastSignInAt || ''
})

export const SprigLogout = () => getSprig()?.('logoutUser')

export const getHomeTabForAnalytics = (tabValue: number): string => {
    switch (tabValue) {
        case 0:
            return 'my_recent_prompts'
        case 1:
            return 'favourites'
        default:
            return 'search'
    }
}