import {Box, Typography} from '@mui/material'
import './TrialBanner.scss'
import {useStripeSubscriptionsContext} from '../../context/StripeSubscriptionsContext'

export const TrialBanner = () => {

    const {isTrial, trialDaysLeft} = useStripeSubscriptionsContext()

    if (!isTrial) return <></>

    const endingSuffix = trialDaysLeft! <= 5 ? 'ending' : ''

    return (
        <Box className='trialBannerContainer'>
            <Box className={`trialBanner ${endingSuffix}`}>
                <Typography className='text' variant='h6'>You're in a free trial! Days remaining:</Typography>
                <Typography className='daysLeft' variant='h6'>{trialDaysLeft}</Typography>
            </Box>
        </Box>
    )
}