import {useUser} from '@clerk/clerk-react'
import {FC, PropsWithChildren, useEffect, useRef} from 'react'
import {SprigLogout} from '../../service/SegmentService'
import {UserResource} from '@clerk/types'

/** When a user logs out of Narus, we ensure to log out of Sprig.
 * This will prevent new activity from being associated with an incorrect user. **/
export const SprigLogoutValidator: FC<PropsWithChildren> = ({children}) => {
    const {user} = useUser()
    const prevUserRef = useRef<UserResource | null | undefined>()

    useEffect(() => {
        const prevUser = prevUserRef.current
        if (prevUser && !user) {
            SprigLogout()
        }
        prevUserRef.current = user
    }, [user])

    return <>{children}</>
}