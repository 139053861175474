export const formatDateStringToLocaleDate = (seconds: number): string => 
	formatDateToLocaleDate(new Date(seconds))

export const formatDateToLocaleDate = (date: Date): string => {
	if (isNaN(date.getTime())) return ''

	return date.toLocaleDateString(navigator.language, {year: 'numeric', month: '2-digit', day: '2-digit'})
}

export const convertToStartOfDay = (date: Date): Date => {
	const startOfDay = new Date(date)
	startOfDay.setUTCHours(0, 0, 0, 0)
	return startOfDay
}

export const getDateAndTime = (dateSeconds: number): string => {
	const date = new Date(dateSeconds * 1000)
	return date.toLocaleString(navigator.language)
}

export const getDaysAgoFromNow = (milliseconds: number): string => {
	const millisecondsPerDay = 24 * 60 * 60 * 1000
	const timeDifference = Date.now() - milliseconds
	const days = Math.floor(timeDifference / millisecondsPerDay)

	return getDaysAgoString(days)
}

export const getDaysAgoString = (numberOfDays: number): string =>
	isNaN(numberOfDays) ? '' : (numberOfDays < 1 ? 'Today' : `${numberOfDays} days ago`)
