import {Autocomplete, Avatar, Box, Button, Divider, Drawer, IconButton, TextField, Typography} from '@mui/material'
import {FC, useCallback, useState} from 'react'
import {useGroupsContext} from '../../../../context/GroupsContext'
import {useUsersAccessContext} from '../../../../context/UsersAccessContext'
import {UserInfo} from '../../../../types/UserInfo'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import {stringAvatar} from '../../../../helpers/AvatarHelpers'
import './GroupAddUsers.scss'

type GroupAddUsersProps = {
    open: boolean
    onClose: () => void
}
export const GroupAddUsers: FC<GroupAddUsersProps> = ({
    open,
    onClose
}) => {
    const {usersInfo} = useUsersAccessContext()
    const {selectedGroup, allGroupsMembers, editingGroupMembers, editGroupMembers} = useGroupsContext()
    const [selectedUsers, setSelectedUsers] = useState<UserInfo[]>([])
    const [userSearchText, setUserSearchText] = useState<string>('')
    const [userAutocomplete, setUserAutocomplete] = useState<UserInfo | null>(null)

    const assignedUserIds = allGroupsMembers.flatMap(({ groupMembers }) => groupMembers).map(({ userId }) => userId)
    const unassignedUsers = usersInfo.filter(({ userId }) => !assignedUserIds.includes(userId))
    const unselectedUsers = unassignedUsers.filter(({ userId }) => !selectedUsers.some(user => user.userId === userId))

    const handleAutocompleteChange = useCallback((_, userInfo: UserInfo | null) => {
        setSelectedUsers(prev => userInfo && prev.every(({ userId}) => userId !== userInfo.userId) ? [...prev, userInfo] : prev)
        setUserSearchText('')
        setUserAutocomplete(null)
    }, [])

    const handleTextFieldChange = useCallback((_, value: string) => {
        setUserSearchText(value)
    }, [])

    const handleUserDeselect = useCallback((user: UserInfo) => {
        setSelectedUsers(prev => prev.filter(({ userId }) => userId !== user.userId))
    }, [])

    const handleClose = useCallback(() => {
        onClose()
        setSelectedUsers([])
    }, [onClose])

    const handleSubmit = useCallback(() => {
        if (selectedGroup) {
            editGroupMembers(selectedGroup.hashKey, selectedUsers.map(({ userId }) => userId))
                .finally(() => {
                    handleClose()
                })
        }
    }, [selectedGroup, selectedUsers, editGroupMembers, handleClose])

    return <Drawer anchor='right' open={open} onClose={handleClose}>
        <Box className='groupAddUsers'>
            <Box className='titleContainer'>
                <Box className='title'>
                    <Box className='icon'>
                        <GroupAddIcon/>
                    </Box>
                    <Typography variant='h4'>Add users</Typography>
                </Box>
                <IconButton onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>
            </Box>
            <Divider variant='fullWidth'/>
            <Typography>
                Select users to add to the team. You can add multiple users at once.
            </Typography>
            <Autocomplete
                options={unselectedUsers}
                getOptionLabel={(user) => user.userFullName}
                value={userAutocomplete}
                inputValue={userSearchText}
                onChange={handleAutocompleteChange}
                onInputChange={handleTextFieldChange}
                renderInput={(params) => <TextField {...params} label='Team member' placeholder='Add member'/>}
                renderOption={(props, user) => {
                    const {className, key, ...optionProps} = props
                    return <Box key={key}
                        component='li'
                        className={`groupAddUsers_menuItem ${className}`}
                        {...optionProps}>
                        <Avatar className='userIcon' src={user.imageUrl} {...stringAvatar(user.userFullName ?? '')} />
                        <Typography className='username'>{user.userFullName}</Typography>                        
                    </Box>}}/>
            <Box className='selectedUsersList'>
            { selectedUsers.map((user, index, array) => <Box key={user.userId}>
                <Box className='selectedUser'>
                    <Box className='title'>
                        <Avatar className='userIcon' src={user.imageUrl} {...stringAvatar(user.userFullName ?? '')} />
                        <Typography>{user.userFullName}</Typography>
                    </Box>
                    <IconButton className='deleteIcon' onClick={() => handleUserDeselect(user)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
                { index < array.length - 1 ? <Divider variant='fullWidth' /> : <></>}
            </Box>) }
            </Box>
            <Box className='footer'>
                <Box className='actions'>
                    <Button variant='contained' disabled={!selectedUsers.length || editingGroupMembers} onClick={handleSubmit}>{editingGroupMembers ? 'Saving' : 'Add users'}</Button>
                    <Button variant='outlined' disabled={editingGroupMembers} onClick={handleClose}>Cancel</Button>
                </Box>
            </Box>
        </Box>
    </Drawer>
}