import {useState} from 'react'
import {Popper, Box, Typography, Button} from '@mui/material'
import './SaveSummarisedPromptNotification.scss'
import SaveSummarisedPromptNotificationDialog from './SaveSummarisedPromptNotificationDialog'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'

interface SaveSummarisedPromptNotificationProps {
    anchorEl: HTMLElement | null
    isOpen: boolean
    onClose: () => void
}

const SaveSummarisedPromptNotification = ({anchorEl, isOpen, onClose}: SaveSummarisedPromptNotificationProps) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const {user} = useUser()

    const handleViewMore = () => {
        setIsDialogOpen(true)
        onClose()
        TrackActionEvent('Summarised Prompt Notification', user?.externalId ?? user?.id, {action: 'click_view_more'})
    }

    const handleDismiss = () => {
        onClose()
        TrackActionEvent('Summarised Prompt Notification', user?.externalId ?? user?.id, {action: 'click_dismiss'})
    }

    return (
        <>
            <Popper className='SaveSummarisedPromptNotification' open={isOpen} anchorEl={anchorEl} placement='bottom'>
                <Box className='SaveSummarisedPromptNotification_Container'>
                    <Box className='SaveSummarisedPromptNotification_Arrow'/>
                    <Box className='SaveSummarisedPromptNotification_Box'>
                        <Typography className='SaveSummarisedPromptNotification_BoxText'>
                            Save the summarised <br/> prompts from this chat
                        </Typography>
                        <Box className='SaveSummarisedPromptNotification_BoxActions'>
                            <Button className='SaveSummarisedPromptNotification_BoxActionsViewMore' onClick={handleViewMore}>
                                View more
                            </Button>
                            <Button className='SaveSummarisedPromptNotification_BoxActionsDismiss' onClick={handleDismiss}>
                                Dismiss
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Popper>
            <SaveSummarisedPromptNotificationDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}/>
        </>
    )
}

export default SaveSummarisedPromptNotification
