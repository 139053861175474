import {GridActionsCellItem, GridColDef, GridRowParams} from '@mui/x-data-grid'
import {useMemo, useState} from 'react'
import {
    Box,
    Button,
    Stack,
    Typography
} from '@mui/material'
import {DataGridTable} from '../../DataGridTable'
import './UsersWorkspace.scss'
import {useUsersAccessContext} from '../../../../context/UsersAccessContext'
import {UserInfo} from '../../../../types/UserInfo'
import {useGroupsContext} from '../../../../context/GroupsContext'
import {getUserGroup} from '../../../../helpers/UserHelpers'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {renderDateCell} from '../../../../helpers/DataGridHelpers'
import {useAdminEmailsContext} from '../../../../context/AdminEmailsContext'
import {AddUsersByEmailDialog} from './AddUsersByEmailDialog'
import {getDaysAgoFromNow, formatDateStringToLocaleDate} from '../../../../helpers/DateHelpers'

export const UsersWorkspace = () => {

    const {loading, usersInfo} = useUsersAccessContext()
    const {groups, allGroupsMembers} = useGroupsContext()
    const {adminEmails} = useAdminEmailsContext()
    const [showAddUsersDialog, setShowAddUsersDialog] = useState<boolean>(false)

    const adminEmailsStrings = adminEmails.map(({email}) => email)

    const columns: GridColDef[] = useMemo(() => [
        {field: 'userFullName', headerName: 'Name', flex: 1, cellClassName: 'userNameCell'},
        {field: 'email', headerName: 'Email', flex: 1, cellClassName: 'userMailCell'},
        {
            field: 'role',
            headerName: 'Role',
            flex: 1,
            cellClassName: 'userRoleCell',
            valueGetter: (_, userInfo: UserInfo) => adminEmailsStrings.includes(userInfo.email) ? 'Admin' : 'Contributor'
        },
        {
            field: 'team',
            headerName: 'Team',
            flex: 1,
            cellClassName: 'userTeamCell',
            valueGetter: (_, userInfo: UserInfo) => getUserGroup(userInfo, allGroupsMembers, groups)?.name ?? ''
        },
        {
            field: 'creationDate',
            headerName: 'Date added',
            flex: 1,
            cellClassName: 'userDateAddedCell',
            renderCell: renderDateCell,
            valueFormatter: formatDateStringToLocaleDate,
            type: 'dateTime',
            valueGetter: (value) => new Date(value)
        },
        {field: 'lastSignInAt', headerName: 'Last active', flex: 1, cellClassName: 'userLastActiveCell', valueFormatter: getDaysAgoFromNow},
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 80,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                    icon={<MoreVertIcon className='actionIcon'/>}
                    label='Manage user'
                    onClick={() => {}}
                />]
        }
    ], [allGroupsMembers, groups, adminEmailsStrings])

    return <>
        <Box className='usersListsContainer'>
            <Stack display='flex' justifyContent='space-between' flexDirection='row'>
                <Typography className='title'>Users</Typography>
                <Button className='addUsersButton' variant='contained' onClick={() => setShowAddUsersDialog(true)}>Add users</Button>
            </Stack>
            <Typography className='subtitle'>Manage workspace users</Typography>
            <DataGridTable rows={usersInfo} columns={columns} loading={loading}
                           analyticEventName='User workspace table'
                           pageSize={20} currentTable='USERS_WORKSPACE'/>
        </Box>
        <AddUsersByEmailDialog open={showAddUsersDialog} setShowDialog={setShowAddUsersDialog}/>
    </>
}