import { Box, TextField, Typography } from '@mui/material'
import { useAccountCreationContext } from '../../context/AccountCreationContext'
import { ChangeEvent } from 'react'
import { isCompanyNameValid } from '../../utils/accountCreationHelpers'

export const AccountCreationFormCompanyName = () => {
    const { form, valid, error, changeFormValue, changeFormValueValid, changeFormValueError} = useAccountCreationContext()
    const showError = valid.companyName === false || !!error.companyName
    const helperText = showError ? (error.companyName || 'Only alphanumeric, and maximum length of 220 characters') : ''

    const handleInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        if (value !== form.companyName) {
            changeFormValue('companyName', value)
        }
        changeFormValueValid('companyName', value.trim() ? isCompanyNameValid(value) : undefined)
    }

    const handleInputFocus = () => {
        changeFormValueError('companyName', undefined)
    }

    return <Box className='AccountCreationForm_Control'>
        <Typography className='AccountCreationForm_ControlSubtitle'>
            Enter a name for your company workspace.
        </Typography>
        <TextField variant='outlined' className='AccountCreationForm_InputField' size='small' fullWidth={true}
                   error={showError} helperText={helperText} value={form.companyName} placeholder='Company name'
                   onChange={handleInputChanged} onFocus={handleInputFocus}/>
    </Box>
}