import React, {useState} from 'react'
import {Box, IconButton, Tooltip, Typography} from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ConfirmationDialog from '../../../promptForm/confirmationDialog/ConfirmationDialog'
import {AdminEmailProps} from '../../../../types/AdminEmailProps'
import './AdminEmail.scss'

export const AdminEmail = ({email, deleteDisabled, confirmDisabled, handleDeleteClicked}: AdminEmailProps) => {

	const [showDeleteAdminEmailDialog, setShowDeleteAdminEmailDialog] = useState<boolean>(false)

	const handleDelete = () => {
		setShowDeleteAdminEmailDialog(false)
		handleDeleteClicked(email)
	}

	return <Box className='accountDomainRow'>
		<Box className='accountDomainName'><Typography>{email}</Typography></Box>
			<Tooltip title={deleteDisabled ? 'Narus needs at least 1 active admin. Add another admin if you want to remove this one.' : ''}>
				<span>
					<IconButton
						onClick={() => confirmDisabled ? handleDeleteClicked(email) : setShowDeleteAdminEmailDialog(true)}
						edge='end'
						disabled={deleteDisabled}
						className={`domainActionIcon ${deleteDisabled ? 'disabled' : ''}`}
					>
						<DeleteOutlineIcon/>
					</IconButton>
				</span>
			</Tooltip>
			<ConfirmationDialog handleClose={() => setShowDeleteAdminEmailDialog(false)} open={showDeleteAdminEmailDialog}
			                    handleDelete={handleDelete}
			                    currentEvent='adminEmailDeletion'/>
		</Box>
}