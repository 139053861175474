import { Box, Typography } from '@mui/material'
import { formatDateStringToLocaleDate } from '../../../../helpers/DateHelpers'
import { useBudgetsContext } from '../../../../context/BudgetContext'
import Spinner from '../../../spinner/Spinner'
import { DataGridTable } from '../../DataGridTable'
import { GridColDef } from '@mui/x-data-grid'
import { useMemo } from 'react'
import { ClosedBudget } from '../../../../types/Budget'
import { useGroupsContext } from '../../../../context/GroupsContext'
import { hasProp } from '../../../../utils/genericUtils'
import './BudgetHistory.scss'


export const BudgetHistory = () => {
    const {closedBudgets, loadingClosedBudgets} = useBudgetsContext()
    const { groups } = useGroupsContext()

    const columns: GridColDef<ClosedBudget>[] = useMemo((): GridColDef<ClosedBudget>[] => [
        {
            headerName: 'Teams',
            field: 'groupId',
            flex: 1,
            valueGetter: (groupId: ClosedBudget['groupId']) => groupId ? (groups.find(hasProp('hashKey', groupId))) : 'Organization limit',
        }, {
            headerName: 'Date of creation',
            field: 'creationDate',
            flex: 1,
            valueGetter: (creationDate: ClosedBudget['creationDate']) => formatDateStringToLocaleDate(creationDate),

        }, {
            headerName: 'Created',
            field: 'creatorEmail',
            flex: 1,
        }, {
            headerName: 'Limit amount',
            field: 'amount',
            flex: 1,
        }, {
            headerName: 'Recurrence',
            field: 'periodicity',
            flex: 1,
        }, {
            headerName: 'Closing date',
            field: 'periodEndDate',
            flex: 1,
            valueGetter: (periodEndDate: ClosedBudget['periodEndDate']) => formatDateStringToLocaleDate(periodEndDate),
        }
    ], [groups])



    return (
        <Box className='budgetHistoryContainer'>
            { loadingClosedBudgets && !closedBudgets.length ? <Spinner/> : <></> }
            { !loadingClosedBudgets && !closedBudgets.length ? <Typography className='budgetHistoryEmpty'>No closed budgets yet</Typography> : <></>}
            { !loadingClosedBudgets && closedBudgets.length ?
                <DataGridTable analyticEventName='BuggetHistory'
                    currentTable='BUDGET_HISTORY'
                    loading={false}
                    rows={closedBudgets}
                    columns={columns} /> : <></>
            }
        </Box>
    )   
}