import {FC} from 'react'
import {AppMenuIconType} from '../../types/AppMenu'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import ChatIcon from '@mui/icons-material/Chat'
import LockIcon from '@mui/icons-material/Lock'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PaymentsIcon from '@mui/icons-material/Payments'
import GroupsIcon from '@mui/icons-material/Groups2'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import SummarizeIcon from '@mui/icons-material/Summarize'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import HelpIcon from '@mui/icons-material/HelpOutline'
import SavedChatIcon from '@mui/icons-material/Save'

type Props = {
    icon: AppMenuIconType
}

const ICONS_MAP: { [key in AppMenuIconType]: () => JSX.Element } = {
    autograph: () => <AutoGraphIcon/>,
    libraryBooks: () => <LibraryBooksIcon/>,
    chat: () => <SavedChatIcon/>,
    lock: () => <LockIcon/>,
    peopleAlt: () => <PeopleAltIcon/>,
    payments: () => <PaymentsIcon/>,
    groups: () => <GroupsIcon/>,
    autoAwesome: () => <AutoAwesomeIcon/>,
    summarize: () => <SummarizeIcon/>,
    spaceDashboard: () => <SpaceDashboardIcon/>,
    settings: () => <SettingsIcon/>,
    help: () => <HelpIcon/>,
    newChat: () => <ChatIcon/>,
    aiAccess: () => <GroupsIcon/>
}

export const AppMenuIcon: FC<Props> = ({ icon }) => ICONS_MAP[icon]()