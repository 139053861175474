import { FC, useEffect, useState } from 'react'
import { AccountCreationAside } from './AccountCreationAside'
import { Box } from '@mui/material'
import { AccountCreationForm } from './AccountCreationForm'
import { StepIndex, StepIndexes } from '../../types/AccountCreation'
import { useAccountCreationContext } from '../../context/AccountCreationContext'
import { AccountCreationError } from './AccountCreationError'
import { AccountCreationProgress } from './AccountCreationPogress'
import { useLocation, useNavigate } from 'react-router-dom'
import { ACCOUNT_CREATION_CONFIG } from '../../constants/AccountCreationConstants'
import { AccountCreationCompleted } from './AccountCreationCompleted'
import { AiModelsConfig, AiModelsContextProvider } from '../../context/AIModelsContext'
import { useUserOrganizationContext } from '../../context/UserOrganizationContext'
import { isUserOrganizationFilled } from '../../utils/userOrganizationUtils'
import { FeedbackMessage } from '../feedbackMessage/FeedbackMessage'
import { AccountCreationWorkspaceCreated } from './AccountCreationWorkspaceCreated'
import { UserButton } from '@clerk/clerk-react'
import { CLERK_ACCOUNT_CREATION_USER_BUTTON_APPEARANCE } from '../../constants/ClerkConstants'
import { getFirstIncompleteIndex } from '../../utils/accountCreationHelpers'

type AccountCreationStepProps = {
    index: StepIndex
}

const aiModelsCustomConfig: AiModelsConfig = {
    retrieveFeedbackVisible: false
}

export const AccountCreationStep: FC<AccountCreationStepProps> = ({ index }) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const { stepsState } = useAccountCreationContext()
    const { userOrganization } = useUserOrganizationContext()

    const [workspaceViewed, setWorkspaceViewed] = useState(false)

    const showProgressPage = stepsState[index] === 'loading' && index === 0
    const showErrorPage = stepsState[index] === 'error'
    const showCompletedPage = stepsState[index] === 'completed' && index === 3
    const firstStepCompleted = stepsState[0] === 'completed'
    const showWorkspaceCreatedPage = firstStepCompleted && index === 1 && !workspaceViewed
    const shouldBeRedirectedToHomePage = isUserOrganizationFilled(userOrganization) && StepIndexes.every(stepIndex => stepsState[stepIndex] !== 'completed')

    const activeIndex = showWorkspaceCreatedPage ? 0 : index
    const completed: Record<StepIndex, boolean> = {
        0: stepsState[0] === 'completed',
        1: stepsState[1] === 'completed',
        2: stepsState[2] === 'completed',
        3: stepsState[3] === 'completed',
    }

    // Don't let user to hardcode step locations not reached
    // Automate navigation to last incomplete page (if there is any)
    useEffect(() => {
        const firstIncompleteIndex = getFirstIncompleteIndex(stepsState)
        if (firstIncompleteIndex !== undefined && firstIncompleteIndex !== index) {
            const { page } = ACCOUNT_CREATION_CONFIG[firstIncompleteIndex]
            navigate(page)
        }
    },[index, pathname, navigate, stepsState])

    // When there is userOrganization, but no step  was completed, it means that
    // This creation process was already done before, so transport the user back to main page
    useEffect(() => {
        if (shouldBeRedirectedToHomePage) {
            navigate('/')
        }
    }, [shouldBeRedirectedToHomePage, navigate])

    return <>
        <Box className='AccountCreation_Container'>
            {
                shouldBeRedirectedToHomePage ? <></> :
                    <Box className='AccountCreation_Content'>
                        <AccountCreationAside index={activeIndex} completed={completed}/>
                        {
                            showCompletedPage ? <AccountCreationCompleted/> :
                            showErrorPage ? <AccountCreationError index={index}/> :
                            showProgressPage ? <AccountCreationProgress/> :
                            showWorkspaceCreatedPage ? <AccountCreationWorkspaceCreated onContinue={() => setWorkspaceViewed(true)} /> :
                            firstStepCompleted ?
                                <AiModelsContextProvider config={aiModelsCustomConfig}>
                                    <AccountCreationForm index={index}/>
                                </AiModelsContextProvider> :
                                <AccountCreationForm index={index}/>
                        }
                    </Box>
            }
        </Box>
        <Box className='AccountCreation_Avatar'>
            <UserButton appearance={CLERK_ACCOUNT_CREATION_USER_BUTTON_APPEARANCE} showName={false}/>
        </Box>
        <FeedbackMessage/>
    </>
}