import {ChangeEvent, FC, useCallback, useState} from 'react'
import {Box, Button, IconButton, InputLabel, Link, TextField, Typography} from '@mui/material'
import {useAiModelsContext} from '../../context/AIModelsContext'
import {getAiModelsList, getAiModelsListForEnterpriseCreation} from '../../helpers/AiModelHelper'
import Select, {components, MultiValue} from 'react-select'
import {SelectOption} from '../../types/SelectOption'
import {AI_MODEL_HEADER_NAMES_MAP, AIModel, AIModelID, AIModels, OPEN_SOURCE_MODELS} from '../../types/AiModel'
import {AiModelIcon} from '../icons/AiModelIcon'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import Switch from '@mui/material/Switch'
import './AccountCreationFormModels.scss'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useAccountCreationContext} from '../../context/AccountCreationContext'
import {useUserOrganizationContext} from '../../context/UserOrganizationContext'
import {LLAMA3_2_LICENSE_URL} from '../../constants/Links'
import {useFeedbackContext} from '../../context/FeedbackContext'

export const AccountCreationFormModels: FC = () => {
	const {aiModels} = useAiModelsContext()
	const {form: {companyName}} = useAccountCreationContext()
	const {userOrganization} = useUserOrganizationContext()
	const {user} = useUser()

	const availableModels = getAiModelsList(aiModels)
	const defaultModels = aiModels.filter(aiModel => [AIModelID.LLAMA_3_2, AIModelID.MISTRAL].includes(aiModel.id))
	const creatableModels = aiModels.filter(aiModel => ![AIModelID.LLAMA_3_2, AIModelID.MISTRAL].includes(aiModel.id))


	const handleLlamaLicenseLinkClicked = () => {
		TrackActionEvent('AI Models', user?.externalId ?? user?.id, {
			company_name: companyName,
			company_id: userOrganization.organizationId,
			company_ai_models: availableModels.map(({value}) => value),
			action: 'view_llama32_license'
		})
	}

	const modelInfoExtras: { [key in AIModelID]?: JSX.Element } = {
		[AIModelID.LLAMA_3_2]: <Typography className='AccountCreationForm_ModelsExtra'>By activating you agree to <Link href={LLAMA3_2_LICENSE_URL} target='llama32License'
		                                                                                                                onClick={handleLlamaLicenseLinkClicked}>LLM's terms and
			conditions</Link></Typography>
	}

	return <Box className='AccountCreationForm_Models'>
		<Typography variant='h4' className='AccountCreationForm_ModelsHeader'>Default AI Models</Typography>
		<Typography variant='body1' className='AccountCreationForm_ModelsSubtitle'>These AI models are free to use. Use the toggles to manage access.</Typography>
		<Box className='AccountCreationForm_ListContainer'>
			{defaultModels.map(model =>
				<AccountCreationFormModel key={model.id} model={model} extra={modelInfoExtras[model.id]} actionsDisabled={true}/>)
			}
		</Box>

		<Typography variant='h4' className='AccountCreationForm_ModelsNewModelHeader'>
			Enterprise and private AI models (Optional)
		</Typography>
		<Typography variant='body1' className='AccountCreationForm_ModelsSubtitle'>
			Use API keys to connect AI models to Narus. Find them in your account menu and add models anytime from the admin screen.
		</Typography>
		{creatableModels.length ? <Box className='AccountCreationForm_ModelsContainer'>
			{creatableModels.map(model => <AccountCreationFormModel key={model.id} model={model} actionsDisabled={false}/>)}
		</Box> : <></>}
		<AccountCreationFormNewModel/>
	</Box>
}

const AccountCreationFormModel: FC<{ extra?: JSX.Element, model: AIModel, actionsDisabled: boolean }> = ({model, actionsDisabled, extra}) => {
	const {deleteAiModel, updateAiModelEnablement} = useAiModelsContext()
	const {userOrganization} = useUserOrganizationContext()
	const {form: {companyName}} = useAccountCreationContext()
	const {user} = useUser()
	const {id, name, isEnabled} = model

	const handleDeleteIconClicked = useCallback(() => {
		deleteAiModel(id).then(() => {
			TrackActionEvent('AI Models', user?.externalId ?? user?.id, {
				company_name: companyName,
				company_id: userOrganization.organizationId,
				company_model_id: id,
				company_model_name: name,
				action: 'delete_model'
			})
		})
	}, [user?.id, user?.externalId, companyName, id, name, deleteAiModel, userOrganization.organizationId])

	const handleEnableSwitchClicked = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		updateAiModelEnablement(event.target.checked, model).then(() => {
			TrackActionEvent('AI Models', user?.externalId ?? user?.id, {
				company_name: companyName,
				company_id: userOrganization.organizationId,
				company_model_id: id,
				company_model_name: name,
				action: event.target.checked ? 'enable_model' : 'disable_model'
			})
		})
	}, [user?.id, user?.externalId, companyName, id, name, updateAiModelEnablement, model, userOrganization.organizationId])


	return <Box className='AccountCreationForm_ModelsItem'>
		<Box className='AccountCreationForm_ModelsName'>
			<AiModelIcon modelId={id}/>
			<Typography>{name}</Typography>
			{extra ? extra : <></>}
		</Box>
		<Box className='AccountCreationForm_ModelsActions'>
			<Switch className={`AccountCreationForm_ModelsSwitch ${actionsDisabled ? '' : 'multipleActions'}`} checked={isEnabled}
			        onChange={handleEnableSwitchClicked}/>
			{actionsDisabled ? <></> : <>
				<IconButton className='AccountCreationForm_ModelsIcon' onClick={handleDeleteIconClicked}><DeleteOutlineIcon/></IconButton>
			</>}
		</Box>
	</Box>
}

const AccountCreationFormNewModel: FC = () => {
	const [id, setId] = useState<string>()
	const [name, setName] = useState('')
	const [apiKey, setApiKey] = useState('')
	const [inProgress, setInProgress] = useState(false)
	const [hasError, setHasError] = useState(false)

	const {userOrganization} = useUserOrganizationContext()
	const {aiModels, addAiModel} = useAiModelsContext()
	const {form} = useAccountCreationContext()
	const {user} = useUser()
	const {showFeedback} = useFeedbackContext()

	const isOpenSourceSelectedModel = id && OPEN_SOURCE_MODELS.map(model => model.id).includes(AIModels[id].id)

	const buttonSubmitDisabled = !(id && name && (apiKey || isOpenSourceSelectedModel)) || inProgress

	const availableModels = getAiModelsListForEnterpriseCreation(aiModels)

	const handleModelIdChanged = (event: MultiValue<SelectOption>) => {
		if (event) {
			const modelId = event.values()?.next()?.value?.value
			setId(modelId)
			const isOpenSourceSelectedModel = modelId && OPEN_SOURCE_MODELS.map(model => model.id).includes(AIModels[modelId].id)
			if (isOpenSourceSelectedModel) setApiKey('')
		}
	}

	const handleModelNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value)
	}

	const handleModelApiKeyChanged = (event: ChangeEvent<HTMLInputElement>) => {
		setHasError(false)
		setApiKey(event.target.value)
	}

	const handleSubmit = () => {
		if (!buttonSubmitDisabled) {
			setInProgress(true)
			addAiModel(id, apiKey, name)
				.then(() => {
					TrackActionEvent('AI Models', user?.externalId ?? user?.id, {
						company_name: form.companyName,
						company_id: userOrganization.organizationId,
						company_model_id: id,
						company_model_name: name,
						action: 'add_model'
					})
				})
				.catch(() => {
					setHasError(true)
					showFeedback('Error', 'We have not been able to add the AI model due to a problem with the API key.', 'error', 10)
				})
				.finally(() => {
					setInProgress(false)
					setId(undefined)
					setName('')
					setApiKey('')
				})
		}
	}

	return <Box className='AccountCreationForm_NewModel'>
		<Box className='AccountCreationForm_NewModelMandatoryFields'>
			<Box className='AccountCreationForm_NewModelAiModel'>
				<InputLabel className='AccountCreationForm_NewModelFieldname'>AI Model</InputLabel>
				<Select required className='AccountCreationForm_NewModelCreatable' placeholder='Select an option' options={availableModels}
			        value={id ? {label: id, value: AI_MODEL_HEADER_NAMES_MAP[id]} : null}
			        onChange={handleModelIdChanged} styles={{menuPortal: base => ({...base, zIndex: 9999})}} menuPortalTarget={document.body} isMulti
			        isOptionDisabled={() => !!id} components={{Option: IconOption, MultiValue: MultiValueOption, IndicatorSeparator: () => <></>}} isClearable={false}
				/>
			</Box>
			<Box className='AccountCreationForm_NewModelAiName'>
				<InputLabel className='AccountCreationForm_NewModelFieldname'> Name</InputLabel>
				<TextField required fullWidth variant='outlined' placeholder='Eg. ChatGPT' value={name} onChange={handleModelNameChanged} size='small'/>
			</Box>
			<Button variant='text' disabled={buttonSubmitDisabled} onClick={handleSubmit} className='AccountCreationForm_NewModelButton'>Add model</Button>
		</Box>
		{!isOpenSourceSelectedModel && <Box className='AccountCreationForm_NewModelAiApiKey'>
            <InputLabel className='AccountCreationForm_NewModelFieldname'>API Key</InputLabel>
            <TextField required fullWidth variant='outlined' error={hasError}
					   placeholder='Eg. SyDaGmWKa4JsXZ-HjGw7ISLn_3nam' value={apiKey} onChange={handleModelApiKeyChanged} size='small'/>
		</Box>
		}
	</Box>
}

const IconOption = (props) => {
	const {Option} = components
	return (
		<Option {...props}>
			<Box className={`AccountCreationForm_NewModelSelectedValue ${props.isDisabled ? 'values_disabled': ''}`}>
				<AiModelIcon modelId={props.value} className='AccountCreationForm_NewModelIcon'/>
				<Typography>{props.data.label}</Typography>
			</Box>
		</Option>
	)
}


const MultiValueOption = (props) => {
	const {MultiValue} = components
	return (
		<MultiValue {...props}>
			<Box className='AccountCreationForm_NewModelSelectedValue'>
				<AiModelIcon modelId={props.data.label}/>
				<Typography>{props.data.value}</Typography>
			</Box>
		</MultiValue>
	)
}