import {useLocation} from "react-router-dom"
import {LoadAnalytics, UnloadAnalytics} from "../service/SegmentService"
import {useEffect, useState} from "react"

const EXTERNAL_PATHS = [
    '/trial',
    '/create-account-company-space',
    '/create-account-company-domain',
    '/create-account-assign-admins',
    '/create-account-connect-ai',
]

export const useSegment = () => {
    const [, setCurrentPathType] = useState<'internal' | 'external'>()
    const { pathname } = useLocation()

    useEffect(() => {
        const isExternal = EXTERNAL_PATHS.some(path => path === pathname)
        const newPathType = isExternal ? 'external' : 'internal'
     
        setCurrentPathType(prevPathType => {
            if (process.env.REACT_APP_SEGMENT_ENABLED === 'true') {
                if (prevPathType) {
                    if (prevPathType !== newPathType) {
                        UnloadAnalytics()
                        LoadAnalytics(isExternal)
                    }
                } else {
                    LoadAnalytics(isExternal)
                }
            }

            return newPathType
        })
        
    }, [pathname])
}