import {useUser} from '@clerk/clerk-react'
import {DataGrid, GridCallbackDetails, gridClasses, GridColDef, GridColumnVisibilityModel, GridLogicOperator, GridSortModel, useGridApiRef} from '@mui/x-data-grid'
import {useCallback, useEffect, useState} from 'react'
import {objectsAreEquals} from '../../helpers/ObjectHelpers'
import {TrackActionEvent} from '../../service/SegmentService'
import {GridFilterModel} from '@mui/x-data-grid/models/gridFilterModel'
import {GridPaginationModel} from '@mui/x-data-grid/models/gridPaginationProps'
import {DataGridCustomToolbar, DataGridSearchToolbar} from './charts/DataGridCustomToolbar'
import {v4 as uuidv4} from 'uuid'
import {useDataGridContext} from '../../context/DataGridContext'
import {useTableContext} from '../../context/TableContext'

export type TableName = 'AUDITING_LOGS' | 'USER_USAGE' | 'USERS_ACCESS' | 'GROUP_MEMBERS' | 'GROUP_LIMITS' | 'USERS_WORKSPACE' | 'BUDGET_HISTORY'

declare module '@mui/x-data-grid' {
	interface ToolbarPropsOverrides {
		currentTable: TableName
	}
}

type DataGridTableProps = {
	columns: GridColDef[]
	rows: any[]
	loading: boolean
	analyticEventName: string
	pageSize?: number
	currentTable: TableName
	fieldToSort?: string
	columnVisibilityModel?: GridColumnVisibilityModel
	hideHeaders?: boolean
	hideToolbar?: boolean
	disableRowSelectionOnClick?: boolean
	rowHeight?: 'auto'
}

export const DataGridTable = ({
	columns,
	rows,
	loading,
	analyticEventName,
	pageSize,
	currentTable,
	fieldToSort,
	columnVisibilityModel,
	hideHeaders,
	hideToolbar,
	disableRowSelectionOnClick,
	rowHeight
}: DataGridTableProps) => {

	const apiRef = useGridApiRef()

	const {page, setPage, setKeepPage} = useTableContext()
	const {user} = useUser()
	const [columnVisibility, setColumnVisibility] = useState<GridColumnVisibilityModel>(columnVisibilityModel ?? {})
	const {
		dataGridState: {
			paginationModel,
			sortModel,
		}, setDataGridState
	} = useDataGridContext()

	const initialColumnsVisibility = columns.reduce(((current: Object, column: GridColDef) => {
		current[column.field] = true
		return current
	}), {})

	const [currentVisibleColumns, setVisibleColumns] = useState(initialColumnsVisibility)

	const columnsChangeHandler = (model: GridColumnVisibilityModel) => {
		setColumnVisibility(model)
		const visibleColumns = columns.reduce(((current: Object, column: GridColDef) => {
			current[column.field] = model[column.field] ?? true
			return current
		}), {})

		if (!objectsAreEquals(visibleColumns, currentVisibleColumns)) {
			TrackActionEvent(analyticEventName, user?.externalId ?? user?.id, {action: 'set_columns', visible_columns: visibleColumns})
			setVisibleColumns(visibleColumns)
		}
	}

	const filterModelChangeHandler = (filterModel: GridFilterModel, details: GridCallbackDetails) => {
		if (details.reason) {
			TrackActionEvent(analyticEventName, user?.externalId ?? user?.id, {
				action: 'filter',
				filter_fields: filterModel.items.map(item => item.field)
			})
			setDataGridState((prevState) => ({...prevState, filterModel}))
		} else {
			TrackActionEvent(analyticEventName, user?.externalId ?? user?.id, {action: 'quick_search'})
		}
	}

	const paginationModelChangeHandler = (paginationModel: GridPaginationModel) => {
		if (paginationModel.page !== page) {
			TrackActionEvent(analyticEventName, user?.externalId ?? user?.id, {action: 'paginate'})
			setPage(paginationModel.page)
			setDataGridState((prevState) => ({...prevState, paginationModel}))
		}
	}

	const sortModelChangeHandler = useCallback((sortModel: GridSortModel) => {
		setDataGridState((prevState) => ({...prevState, sortModel}))
	}, [setDataGridState])

	useEffect(() => {
		setColumnVisibility(columnVisibilityModel ?? {})
	}, [columnVisibilityModel])

	useEffect(() => {
		setKeepPage(prevState => {
			if (!prevState) setPage(0)
			return false
		})
	}, [setKeepPage, setPage])

	return <DataGrid
		apiRef={apiRef}
		rows={rows ?? []}
		columns={columns}
		getRowId={row => row.userId ?? row.groupId ?? row.hashKey ?? uuidv4()}
		loading={loading}
		autoHeight={true}
		density='compact'
		onColumnVisibilityModelChange={columnsChangeHandler}
		onFilterModelChange={filterModelChangeHandler}
		getRowHeight={() => rowHeight}
		initialState={{
			pagination: {
				paginationModel: {page, pageSize: pageSize ?? paginationModel.pageSize},
			},
			filter: {
				filterModel: {
					items: [],
					quickFilterLogicOperator: GridLogicOperator.Or,
				},
			},
			sorting: {
				sortModel: fieldToSort ? [{field: fieldToSort, sort: 'desc'}] : []
			}
		}}
		columnVisibilityModel={columnVisibility}
		sx={{
			border: 'none',
			[`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: disableRowSelectionOnClick && {
				outline: 'transparent',
			},
			[`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: disableRowSelectionOnClick && {
				outline: 'none',
			},
			...(hideHeaders ? {
				'& .MuiDataGrid-columnHeaders': {
					display: 'none'
				}
			} : {})
		}}
		disableDensitySelector
		slots={{toolbar: hideToolbar ? DataGridSearchToolbar : DataGridCustomToolbar}}
		pageSizeOptions={[10, 20, 50]}
		onPaginationModelChange={paginationModelChangeHandler}
		onSortModelChange={sortModelChangeHandler}
		sortModel={hideHeaders && fieldToSort ? [{field: fieldToSort, sort: 'desc'}] : sortModel}
		slotProps={{
			toolbar: {
				currentTable
			},
		}}
		disableRowSelectionOnClick={disableRowSelectionOnClick}
	/>
}