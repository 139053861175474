import { Box, CircularProgress, Typography } from '@mui/material'
import './AccountCreationProgress.scss'

export const AccountCreationProgress = () => <Box className='AccountCreationProgress'>
    <Box className='AccountCreationProgress_Icon'>
        <svg width="0" height="0">
            <linearGradient id="gradientSpinnerColors" x1="0" y1="0" x2="1" y2="1">
                <stop offset="20%" stopColor="#289BA8" />
                <stop offset="50%" stopColor="#A375FC" />
            </linearGradient>
        </svg>
        <CircularProgress size={48} className='gradientIcon'/>
    </Box>
    <Box className='AccountCreationProgress_Content'>
        <Typography variant='h6' className='AccountCreationProgress_Title'>Synchronizing data and creating workspaces</Typography>
        <Typography variant='body1' className='AccountCreationProgress_Subtitle'>We are creating your workspace and connecting to your base, this may take a few minutes.</Typography>
    </Box>
</Box>