import { AccountCreationFormAdminEmails } from '../components/accountCreation/AccountCreationFormAdminEmails'
import { FC } from 'react'
import { AccountCreationFormCompanyDomains } from '../components/accountCreation/AccountCreationFormCompanyDomains'
import { AccountCreationFormCompanyName } from '../components/accountCreation/AccountCreationFormCompanyName'
import { AccountCreationFormModels } from '../components/accountCreation/AccountCreationFormModels'
import { Config, ContextValue, FormMap, FormTemplate, StepsState } from '../types/AccountCreation'

export const DEFAULT_ACCOUNT_CREATION_FORM_TEMPLATE: FormTemplate = {
    adminEmails: [],
    companyDomains: [],
    companyName: '',
    models: []
}

export const DEFAULT_ACCOUNT_CREATION_FORM_VALID: FormMap<boolean | undefined> = {
    adminEmails: true,
    companyDomains: undefined,
    companyName: undefined,
    models: true
}

export const DEFAULT_ACCOUNT_CREATION_ERROR: FormMap<string | undefined> = {
    adminEmails: undefined,
    companyDomains: undefined,
    companyName: undefined,
    models: undefined
}

export const DEFAULT_ACCOUNT_CREATION_STATE: StepsState = {
    '0': 'idle',
    '1': 'idle',
    '2': 'idle',
    '3': 'idle'
}

export const ACCOUNT_CREATION_COMPONENT_MAP: FormMap<FC> = {
    adminEmails: AccountCreationFormAdminEmails,
    companyDomains: AccountCreationFormCompanyDomains,
    companyName: AccountCreationFormCompanyName,
    models: AccountCreationFormModels,
}

export const DEFAULT_ACCOUNT_CREATION_CONTEXT_VALUE: ContextValue = {
    form: DEFAULT_ACCOUNT_CREATION_FORM_TEMPLATE,
    stepsState: DEFAULT_ACCOUNT_CREATION_STATE,
    valid: DEFAULT_ACCOUNT_CREATION_FORM_VALID,
    error: DEFAULT_ACCOUNT_CREATION_ERROR,
    changeFormValue: () => {},
    changeFormValueValid: () => {},
    changeFormValueError: () => {},
    resetStepState: () => {},
    submitStep: () => {}
}

export const ACCOUNT_CREATION_CONFIG: Config = {
    '0': {
        title: 'Company Space',
        formTitle: 'Company Space',
        page: '/create-account-company-space',
        controls: ['companyName'],
        validators: ['companyName'],
        actionTitle: 'Continue',
        pageAnalytics: 'Company onboarding company space'
    },
    '1': {
        title: 'Company Domain',
        formTitle: 'Company Domain',
        page: '/create-account-company-domain',
        controls: ['companyDomains'],
        validators: ['companyDomains'],
        actionTitle: 'Continue',
        skipTitle: 'Skip for now',
        pageAnalytics: 'Company onboarding company domain'
    },
    '2': {
        title: 'Define Admins',
        formTitle: 'Assign admin permissions',
        page: '/create-account-assign-admins',
        controls: ['adminEmails'],
        validators: ['adminEmails'],
        actionTitle: 'Continue',
        skipTitle: 'Skip for now',
        pageAnalytics: 'Company onboarding admin accounts'
    },
    '3': {
        title: 'Add AI models',
        formTitle: 'Connect AI models',
        page: '/create-account-connect-ai',
        controls: ['models'],
        validators: ['models'],
        actionTitle: 'Finalize process',
        skipTitle: 'Skip for now',
        pageAnalytics: 'Company onboarding AI models'
    }
}

export const ACCOUNT_CREATION_ERRORS: { prop: keyof FormTemplate, message: string, error: string}[] = [
    {
        prop: 'companyName',
        message: 'This company name has already been used in Narus. Try a different one.',
        error: 'Invalid request, suffix is in use'
    },
    {
        prop: 'companyName',
        message: 'This company name has already been used in Narus. Try a different one.',
        error: 'Invalid request, organization name provided is in use'
    },
    {
        prop: 'companyName',
        message: 'Company name is too long.',
        error: 'Invalid request, suffix is very long'
    }
]