import {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useUserContext} from './UserContext'
import {FileId, FileMetadata, FileUpload} from '../types/File'
import {checkFilesLoadStatus, deleteFile, getUploadFileURL, putFile} from '../service/persistenceService'
import {useUser} from '@clerk/clerk-react'
import {
    areFileUploadsMatch,
    areFileUploadsMatchChanged,
    createFileUpload,
    failPendingFileUpload,
    getMetadata,
    getState,
    hasId,
    isFileSizeValid,
    isFileTypeInvalid,
    isPendingFileUpload,
    updateFileUploadWithError,
    updateFileUploadWithFilesState
} from '../utils/fileUtils'
import {put, remove, updateWithComposition} from '../utils/genericUtils'
import {AIModelID} from '../types/AiModel'
import {MAX_CHECK_FILE_RETRIES } from '../constants/FileConstants'

type FilesContextValue = {
    files: FileUpload[]
    isLoading: boolean
    /** Remove files from context without removing them from database
     * @param filterCondition If present, clear fileUploads based on filter condition. If not, clear all */
    clearFiles: (filterCondition?: (fileUpload: FileUpload) => boolean) => void
    removeFile: (fileUpload: FileUpload | FileMetadata) => void
    removeFiles: (files: Array<FileUpload | FileMetadata>) => void
    retryUpload: (fileUpload: FileUpload) => void
    uploadFile: (file: File, modelId?: AIModelID, conversationId?: string) => void
}

const DEFAULT_FILES_CONTEXT_VALUE: FilesContextValue = {
    files: [],
    isLoading: false,
    clearFiles: () => {},
    removeFile: () => {},
    removeFiles: () => {},
    retryUpload: () => {},
    uploadFile: () => {},
}

const FilesContext = createContext<FilesContextValue>(DEFAULT_FILES_CONTEXT_VALUE)

export const useFilesContext = () => useContext(FilesContext)

export const FilesContextProvider: FC<PropsWithChildren> = ({children}) => {
    const {token} = useUserContext()
    const {user} = useUser()
    const [fileUploads, setFileUploads] = useState<FileUpload[]>([])
    const [checkRetries, setCheckRetries] = useState(0)

    const userId = (user?.id ?? user?.externalId)!

    const clearFiles = useCallback((filterCondition?: (fileUpload: FileUpload) => boolean) => {
        setFileUploads(prev => filterCondition ? prev.filter(filterCondition) : [])
    }, [])

    const checkFilesState = useCallback((fileIds: FileId[]): () => void => {
        const timeout = setTimeout(() => {
            checkFilesLoadStatus(token, fileIds).then(filesState => {
                setFileUploads(updateWithComposition(updateFileUploadWithFilesState(filesState), areFileUploadsMatchChanged))
            })
            .finally(() => {
                setCheckRetries(prev => prev + 1)
            })
        }, 2000)
        
        return () => clearTimeout(timeout)
    }, [token])

    const upload = useCallback((fileUpload: FileUpload) => {
        const loadingFileUpload: FileUpload = { ...fileUpload, state: 'loading' }
        const {metadata: {conversationId, id: fileId}, file} = fileUpload
        setCheckRetries(0)
        setFileUploads(put(loadingFileUpload, areFileUploadsMatch, areFileUploadsMatchChanged))
        getUploadFileURL(token, fileId, conversationId)
            .then(({uploadURL}) => putFile(uploadURL, file))
            .catch(() => {
                const failedFiledUpload: FileUpload = updateFileUploadWithError(fileUpload, 'internal')
                setFileUploads(put(failedFiledUpload, areFileUploadsMatch, areFileUploadsMatchChanged))
            })
    }, [token])
    
    const uploadFile = useCallback((file: File, modelId?: AIModelID, conversationId?: string) => {
        const fileUpload: FileUpload = createFileUpload(file, modelId, userId, conversationId)
        if (!isFileSizeValid(file)) {
            setFileUploads(prev => [...prev, updateFileUploadWithError(fileUpload, 'size')])
        } else if (isFileTypeInvalid(file)) {
            setFileUploads(prev => [...prev, updateFileUploadWithError(fileUpload, 'type')])
        } else {
            setFileUploads(put(fileUpload, areFileUploadsMatch, areFileUploadsMatchChanged))
            upload(fileUpload)
        }
    }, [upload, userId])

    const retryUpload = useCallback((fileUpload: FileUpload) => {
        upload(fileUpload)
    }, [upload])

    const removeFile = useCallback((file: FileUpload | FileMetadata) => {
        const { id } = getMetadata(file)
        if (getState(file) === 'completed') {
            deleteFile(token, id)
        }
        setFileUploads(remove(hasId(id)))
    }, [token])

    const removeFiles = useCallback((files: Array<FileUpload | FileMetadata>) => {
        files.forEach(removeFile)
    }, [removeFile])

    useEffect(() => {
        const pendingFileUploads = fileUploads.filter(isPendingFileUpload)
        let cancel: () => void

        if (pendingFileUploads.length && checkRetries < MAX_CHECK_FILE_RETRIES) {
            const fileIds = pendingFileUploads.map(file => file.metadata.id)
            cancel = checkFilesState(fileIds)
        } else if (pendingFileUploads.length && checkRetries >= MAX_CHECK_FILE_RETRIES) {
            setFileUploads(updateWithComposition(failPendingFileUpload, areFileUploadsMatchChanged))
        }

        return () => {
            cancel?.()
        }
    }, [fileUploads, checkFilesState, checkRetries])

    const value: FilesContextValue = useMemo(() => ({
        files: fileUploads,
        isLoading: !!fileUploads.length && fileUploads.some(isPendingFileUpload),
        clearFiles,
        uploadFile,
        retryUpload,
        removeFile,
        removeFiles,
    }), [fileUploads, clearFiles, removeFile, removeFiles, retryUpload, uploadFile])

    return (
        <FilesContext.Provider value={value}>
            {children}
        </FilesContext.Provider>
    )
}