import {FC} from 'react'
import {Box, Tooltip, Typography} from '@mui/material'
import './CustomEllipsedText.scss'

type Props = {
    children: string
    className: string
    hasTooltip?: true
    /** Amount of characters displayed at right after text has ellipsed */
    ellipsisOffset?: number
}

/**
 * Custom component that will render Material typography text
 * with an ellipsis at a specific right offset
 */
export const CustomEllipsedText: FC<Props> = ({
    children,
    className,
    hasTooltip,
    ellipsisOffset = 9,
}) => {

    const textStart = children.slice(0, children.length - ellipsisOffset)
    const textEnd = children.slice(-ellipsisOffset)
    const classNameStart = `CustomEllipsedText_Start${className ? ` ${className}` : ''}`
    const classNameEnd = className ?? ''

    const mainContent = children.length > ellipsisOffset
        ? <Box className='CustomEllipsedText_Container'>
            <Typography className={classNameStart}>{textStart}</Typography>
            <Typography className={classNameEnd}>{textEnd}</Typography>
        </Box>
        : <Typography className={classNameStart}>{children}</Typography>

    return hasTooltip 
        ? <Tooltip title={children}>
            {mainContent}
        </Tooltip>
        : mainContent
}