import {AsyncState} from './AsyncState'

export type FileId = string
export type FileMetadata = {
    id: FileId
    name: string
    timestamp: number
    modelId?: string
    ownerId?: string
    conversationId?: string
    messageId?: string
}

export type FileUpload = {
    file: File,
    state: AsyncState,
    metadata: FileMetadata,
    errorType?: FileError
    errorMessage?: string
}

export const FileBlockingErrors = [
    'size',
    'type',
] as const 

export type FileBlockingError = typeof FileBlockingErrors[number]
export type FileInternalError = 'internal'
export type FileError = FileBlockingError | FileInternalError

export type CheckFileState = {
    id: string
    is_loaded: boolean
    error: string | null
}