import {AIModelID} from '../../types/AiModel'
import {ReactComponent as OpenAiIcon} from '../../images/openai.svg'
import {ReactComponent as GoogleIcon} from '../../images/google.svg'
import {ReactComponent as LlamaIcon} from '../../images/llama.svg'
import {ReactComponent as MistralIcon} from '../../images/mistral.svg'
import {ReactComponent as ClaudeIcon} from '../../images/claude.svg'
import {ReactComponent as AmazonQIcon} from '../../images/amazon-q.svg'
import {AiModelIconProps} from '../../types/AiModelIconProps'
import {isAiModel} from '../../helpers/AiModelHelper'

export const AiModelIcon = ({modelId, className}: AiModelIconProps) => {
    if (isAiModel(modelId)) {
        switch (modelId) {
            case AIModelID.MISTRAL:
                return <MistralIcon className={className}/>
            case AIModelID.LLAMA_2:
            case AIModelID.LLAMA_3:
            case AIModelID.LLAMA_3_1:
            case AIModelID.LLAMA_3_2:
                return <LlamaIcon className={className}/>
            case AIModelID.GOOGLE_GEMINI:
            case AIModelID.GOOGLE_GEMINI_1_5_FLASH:
            case AIModelID.GOOGLE_GEMINI_1_5_FLASH_002:
            case AIModelID.GOOGLE_GEMINI_1_5_PRO:
            case AIModelID.GOOGLE_GEMINI_1_5_PRO_002:
            case AIModelID.GOOGLE_GEMINI_2_0_PRO:
            case AIModelID.GOOGLE_GEMINI_2_5_PRO:
            case AIModelID.GOOGLE_GEMMA_2:
                return <GoogleIcon className={className}/>
            case AIModelID.CLAUDE_3_5_HAIKU:
            case AIModelID.CLAUDE_3_HAIKU:
            case AIModelID.CLAUDE_3_5_SONNET:
            case AIModelID.CLAUDE_3_7_SONNET:
            case AIModelID.CLAUDE_3_OPUS:
                return <ClaudeIcon className={className}/>
            case AIModelID.AMAZON_Q:
                return <AmazonQIcon className={className}/>
            case AIModelID.GPT_3_5:
            case AIModelID.GPT_4:
            case AIModelID.GTP_4_O:
            case AIModelID.GPT_4_O_MINI:
            default:
                return <OpenAiIcon className={className}/>
        }
    } else {
		return <></>
	}
}