import {useCallback, useMemo, useState} from 'react'
import { ACCOUNT_CREATION_ERRORS, DEFAULT_ACCOUNT_CREATION_ERROR, DEFAULT_ACCOUNT_CREATION_FORM_TEMPLATE, DEFAULT_ACCOUNT_CREATION_FORM_VALID, DEFAULT_ACCOUNT_CREATION_STATE } from '../constants/AccountCreationConstants'
import { ChangeErrorCallback, ChangeValidCallback, ChangeValueCallback, ContextValue, FormMap, FormTemplate, StepIndex, StepsState } from '../types/AccountCreation'
import {
    createAdminEmails,
    createOrganizationWithRetries,
    updateOrganization,
} from '../service/persistenceService'
import { useUserContext } from '../context/UserContext'
import {
    getFormAdminEmails,
    getFormCompanyDomains,
    getFormCompanyName,
    getFormTableSuffix
} from '../utils/accountCreationHelpers'
import {useUserOrganizationContext} from '../context/UserOrganizationContext'
import {useUser} from '@clerk/clerk-react'
import {AwsMarketplaceCustomerData} from '../types/AwsMarketplaceCustomerData'

export const useAccountCreation = (): ContextValue => {
    const {setUserOrganization} = useUserOrganizationContext()
    const {token} = useUserContext()
    const {user} = useUser()
    const [form, setForm] = useState<FormTemplate>(DEFAULT_ACCOUNT_CREATION_FORM_TEMPLATE)
    const [stepsState, setStepsState] = useState<StepsState>(DEFAULT_ACCOUNT_CREATION_STATE)
    const [valid, setValid] = useState<FormMap<boolean | undefined>>(DEFAULT_ACCOUNT_CREATION_FORM_VALID)
    const [error, setError] = useState<FormMap<string | undefined>>(DEFAULT_ACCOUNT_CREATION_ERROR)

    const changeFormValue: ChangeValueCallback = useCallback((prop, value) => {
        setForm(prev => ({ ...prev, [prop]: value }))
    }, [])

    const changeFormValueValid: ChangeValidCallback = useCallback((prop, value) => {
        setValid(prev => ({ ...prev, [prop]: value }))
    }, [])

    const changeFormValueError: ChangeErrorCallback = useCallback((prop, value) => {
        setError(prev => ({ ...prev, [prop]: value }))
    }, [])

    const resetStepState = useCallback((index: StepIndex) => {
        setStepsState(prev => ({ ...prev, [index]: 'idle' }))
    }, [])

    const submitStep = useCallback((index: StepIndex, skip?: boolean) => {
        const actionStepMap: { [key in StepIndex]: () => Promise<any> } = {
            '0': async () => {
                const userEmail = user?.emailAddresses[0]?.emailAddress ? [user.emailAddresses[0].emailAddress] : []
                const awsMarketplaceCustomerDataJSON = localStorage.getItem('awsMarketplaceCustomerData')
                const awsMarketplaceCustomerData = awsMarketplaceCustomerDataJSON ? JSON.parse(awsMarketplaceCustomerDataJSON) as AwsMarketplaceCustomerData : {}
                return createOrganizationWithRetries(token, getFormCompanyName(form), getFormTableSuffix(form), userEmail, awsMarketplaceCustomerData)
                    .then(userOrganization => {
                        setUserOrganization(userOrganization)
                    })
            },
            '1': ()=> skip ? Promise.resolve({}) : updateOrganization(token, getFormCompanyName(form), getFormCompanyDomains(form)),
            '2': () => form.adminEmails.length && !skip ? createAdminEmails(token, getFormAdminEmails(form)) : Promise.resolve({}),
            '3': () => Promise.resolve({})
        }

        setStepsState(prev => ({ ...prev, [index]: 'loading' }))
        actionStepMap[index]()
            .then(() => {
                setStepsState(prev => ({ ...prev, [index]: 'completed' }))
            })
            .catch(error => {
                const errorMessage: string = error?.response?.data?.message ?? ''
                const errorMatch = ACCOUNT_CREATION_ERRORS.find(creationError => creationError.error === errorMessage)

                if(index === 1) {
                    setError(prev => ({ ...prev, 'companyDomains': error.message }))
                    setStepsState(prev => ({ ...prev, [index]: 'idle' }))
                } else if (errorMatch) {
                    setError(prev => ({ ...prev, [errorMatch.prop]: errorMatch.message }))
                    setStepsState(prev => ({ ...prev, [index]: 'idle' }))
                } else {
                    setStepsState(prev => ({ ...prev, [index]: 'error' }))
                }
            })
    }, [token, form, setUserOrganization, user?.emailAddresses])

    return useMemo(() => ({
        form,
        stepsState,
        valid,
        error,
        changeFormValue,
        changeFormValueValid,
        changeFormValueError,
        resetStepState,
        submitStep
    }), [form, stepsState, valid, error, changeFormValue, changeFormValueValid, changeFormValueError, resetStepState, submitStep])
}