import {Box, Button, Link, Typography} from '@mui/material'
import {FC, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {PageView, TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useAccountCreationContext} from '../../context/AccountCreationContext'
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import './AccountCreationCompleted.scss'
import { APP_URL } from '../../constants/Links'

export const AccountCreationCompleted: FC = () => {
    const navigate = useNavigate()
    const {user} = useUser()
    const {form} = useAccountCreationContext()

    const handleButtonClicked = () => {
        TrackActionEvent('Registration Completed', user?.externalId ?? user?.id, {company_name: form.companyName})
        navigate('/')
    }

    useEffect(() => PageView('Account creation completed'), [])

	return (
        <Box className='AccountCreation_Main'>
            <CheckIcon className='AccountCreationCompleted_Icon'/>
            <Typography className='AccountCreationCompleted_Title'>
                Workspace Ready
            </Typography>
            <Typography className='AccountCreationCompleted_Text'>
                Continue to Narus to complete the creation of your workspace and give access to your entire organization. Users with Admin privileges have received a welcome email.
            </Typography>
            <Typography className='AccountCreationCompleted_SecondaryTitle'>
                What next?
            </Typography>
            <Typography className='AccountCreationCompleted_SecondaryText'>
                Review user access and set spending limits before inviting your team.
            </Typography>
            <Typography className='AccountCreationCompleted_SecondaryText'>
                Once ready, users can sign in at <Link className='AccountCreationCompleted_Link' underline='hover' href={APP_URL} target='appUrl'>app.narus.ai</Link>
            </Typography>
            <Button className='AccountCreationCompleted_Action' variant='contained' size='large' onClick={handleButtonClicked}>
                Go to narus
            </Button>
        </Box>
    )
}