import React, {ChangeEvent, Dispatch, SetStateAction, useState} from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Typography, Box, AccordionSummary, AccordionDetails, Accordion, Divider
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import './AddUsersByEmailDialog.scss'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {emailMatchesDomain, isEmailAddress} from '../../../../utils/accountCreationHelpers'
import CloseIcon from '@mui/icons-material/Close'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useOrganizationContext} from '../../../../context/OrganizationContext'
import {useUser} from '@clerk/clerk-react'

interface AddUsersByEmailDialogProps {
    open: boolean
    setShowDialog: Dispatch<SetStateAction<boolean>>
}

export const AddUsersByEmailDialog = ({open, setShowDialog}: AddUsersByEmailDialogProps) => {

    const [emailsInput, setEmailsInput] = useState<string>()
    const [emailValidError, setEmailValidError] = useState<boolean>(false)
    const [validationError, setValidationError] = useState<string>('')

    const {user} = useUser()
    const {organization, editOrganization} = useOrganizationContext()

    const deleteDisabled = organization?.allowedDomains?.length !== undefined && organization?.allowedDomains?.length <= 1
    const allowedDomainEmails = organization?.allowedDomains.filter(domain =>
        isEmailAddress(domain) && !(user?.emailAddresses.length && emailMatchesDomain(user.emailAddresses[0].emailAddress)(domain)))

    const handleClose = () => {
        setEmailValidError(false)
        setValidationError('')
        setShowDialog(false)
    }

    const handleSaveEmailDomain = async () => {
        if (emailsInput) {
            let allowedDomains: string[] | undefined = []
            const emails = emailsInput.split(',').map(email => email.trim())

            const invalidFormatEmail = emails.some(email => !isEmailAddress(email))
            if (invalidFormatEmail) {
                setEmailValidError(true)
                setValidationError('Your input doesn\'t match an email format. Try a format like name@yourcompany.com')
                return
            }

            const existingEmail = emails.some(email => organization?.allowedDomains.includes(email))
            if (existingEmail) {
                setEmailValidError(true)
                setValidationError('That email already exists. Try adding a different one')
                return
            }

            const error = await editOrganization(organization?.name ?? '', [...(organization?.allowedDomains ?? []), ...emails])
            if (error) {
                setValidationError(error)
                setEmailValidError(true)
                return
            }

            TrackActionEvent('Organization settings', user?.externalId ?? user?.id, {
                action: 'addUserEmail',
                organization_domains_count: allowedDomains.length
            })
            setEmailValidError(false)
            setEmailsInput('')
        }
    }

    const handleDeleteEmailDomainClicked = async (emailDomain: string) => {
        if (deleteDisabled || (user?.emailAddresses.length && emailMatchesDomain(user?.emailAddresses[0].emailAddress)(emailDomain))) return
        const error = await editOrganization(organization?.name ?? '', organization?.allowedDomains?.filter(allowedDomain => allowedDomain !== emailDomain) ?? [])
        if (!error) TrackActionEvent('Organization settings', user?.externalId ?? user?.id, {
            action: 'removeDomain',
            organization_domains_count: organization?.allowedDomains?.length ?? 0
        })
    }

    const handleAddEmails = (event: ChangeEvent<HTMLInputElement>) => {
        if (emailValidError && !event.target.value) {
            setEmailValidError(false)
            setValidationError('')
        }
        setEmailsInput(event.target.value)
    }

    return <Dialog open={open} onClose={handleClose} fullWidth className='addUsersDialog_Container' maxWidth='md'>
        <DialogTitle className='addUsersDialog_TitleContainer'>
            <Typography variant='h2' className='addUsersDialog_Title'>Add users by email</Typography>
            <CloseIcon className='addUsersDialog_CloseIcon' onClick={handleClose}/>
        </DialogTitle>
        <DialogContent className='addUsersDialog_Content'>
            <Typography variant='body2' gutterBottom className='addUsersDialog_Description'>
                Enter users’ email addresses to grant access. They can join the workspace once they create an account.
                Note: Narus does not send invitations. Please share access details manually.
            </Typography>
            <Box className='addUsersDialog_InputContainer'>
                <Box className='addUsersDialog_TextfieldContainer'>
                    <TextField
                        label='Write one or more emails'
                        placeholder='example@email.com'
                        value={emailsInput}
                        size='small'
                        onChange={handleAddEmails}
                        fullWidth
                        error={emailValidError}
                        helperText={emailValidError ? validationError : ''}
                    />
                </Box>
                <Box className='addUsersDialog_ButtonContainer'>
                    <Button
                        variant='outlined'
                        onClick={handleSaveEmailDomain}
                        className='addUsersDialog_Button'
                        fullWidth
                    >
                        Add user
                    </Button>
                </Box>
            </Box>
            {!emailValidError && <Box>
                <InfoIcon fontSize='small' className='addUsersDialog_InfoIcon'/>
                <Typography variant='caption'>
                    To add multiple users, list emails separated with a comma.
                </Typography>
            </Box>}
            {allowedDomainEmails?.length ?
                <Accordion defaultExpanded={true} className='usersAddedAccordion_Container'>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} className='usersAddedAccordion_Summary'>
                        <Typography className='usersAddedAccordion_Title'>Users added</Typography>
                    </AccordionSummary>
                    <AccordionDetails className='usersAddedAccordion_Details'>
                        <List className='usersAddedAccordion_List'>
                            {allowedDomainEmails.map((domainEmail, index) => (
                                <>
                                    <ListItem
                                        key={`${domainEmail}-${index}`}
                                        className='usersAddedAccordion_ListItem'
                                        secondaryAction={
                                            <IconButton edge='end' aria-label='delete'
                                                        className='usersAddedAccordion_ListIcon'
                                                        onClick={() => handleDeleteEmailDomainClicked(domainEmail)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        }>
                                        <ListItemText primary={domainEmail}/>
                                    </ListItem>
                                    {allowedDomainEmails.length > 1 && index !== allowedDomainEmails.length - 1 &&
                                        <Divider className='usersAddedAccordion_Divider'/>}
                                </>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion> : <></>}
        </DialogContent>
    </Dialog>
}