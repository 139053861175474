import {FC, useMemo} from 'react'
import {Box, Grid} from '@mui/material'
import {DataGridTable} from '../../DataGridTable'
import {GridColDef} from '@mui/x-data-grid'
import {formatDateStringToLocaleDate} from '../../../../helpers/DateHelpers'
import './GroupTable.scss'
import {GroupMember} from '../../../../types/GroupMember'
import {renderDateCell} from '../../../../helpers/DataGridHelpers'
import {useUsersAccessContext} from '../../../../context/UsersAccessContext'
import {useAdminEmailsContext} from '../../../../context/AdminEmailsContext'


const columns: GridColDef[] = [
    {
        field: 'name', 
        headerName: 'Name', 
        flex: 1, 
        cellClassName: 'userInfoCell',
    }, 
    {
        field: 'email',
        headerName: 'Email',
        flex: 1,
        cellClassName: 'userInfoCell',
    }, 
    {
        field: 'role',
        headerName: 'Role',
        flex: 1,
        cellClassName: 'userInfoCell',
    },
    {
        field: 'entryDate', 
        headerName: 'Entry date', 
        flex: 1, 
        cellClassName: 'userInfoCell', 
        valueFormatter: formatDateStringToLocaleDate, 
        renderCell: renderDateCell, 
        valueGetter: (value) => new Date(value)
    }
]

type Props = {
    groupMembers: GroupMember[]
}

export const GroupTable: FC<Props> = ({
    groupMembers
}) => {
    const {userInfoMap} = useUsersAccessContext()
    const {adminEmails} = useAdminEmailsContext()

    const rows = useMemo(() => groupMembers.map(({userId, entryDate}) => ({
        name: userInfoMap[userId]?.userFullName,
        email: userInfoMap[userId]?.email,
        role: adminEmails.find(({ email }) => userInfoMap[userId]?.email === email) ? 'Admin' : 'Contributor',
        entryDate,
    })), [groupMembers, userInfoMap, adminEmails]) 

    return <Grid item xs={12}>
        <Box className='teamTableContainer'>
            <DataGridTable
                fieldToSort='userFullName'
                columns={columns}
                rows={rows}
                loading={false}
                analyticEventName='Groups'
                currentTable='GROUP_MEMBERS'
            />
        </Box>
    </Grid>
}